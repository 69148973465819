import { React, Fragment, useState, useRef } from "react";
import Masonry from "react-smart-masonry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import SingleCard from "./singleCard";
import SingleBucket from "../SingleBucket";
import axios from "axios";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
import ShareCard from "./shareCard";
import moment from "moment";
import Files from "../../components/Files/Files.scss";
// import {ReactComponent as CalenderIcon} from "../../assets/icons/calendarIcon.svg";
import compactCardIcon from "../../assets/icons/gridcard.svg";
import { useOutletContext } from "react-router-dom";
import CreateBucket from "../CreateBucket";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import CalenderIcon from "../../assets/icons/calendar.svg";
import FolderBucket from "../../assets/icons/folderbucket.svg";
import FileBucket from "../../assets/icons/fileBucket.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import Arrow from "../../assets/icons/arrow.svg";
import FilesOrange from "../../assets/images/filesOrange.svg";
import BookmarksOrange from "../../assets/images/bookmarksOrange.svg";
import ListView from "../../assets/icons/listView.svg";
import Document from "../../assets/images/document.svg";
import ImageFolder from "../../assets/images/imageFolder.svg";
import PDF from "../../assets/images/pdf.svg";
import ClipBoard from "../../assets/icons/notesnewicon.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import ProjectImg2 from "../../assets/images/Projects/projectImg2.png";
import Profile3 from "../../assets/icons/profile3.png";
import CardImage1 from "../../assets/images/cardImage1.png";
import CardImage2 from "../../assets/images/cardImage2.png";
import CardImage3 from "../../assets/images/cardImage3.svg";
import linkIcon from "../../assets/icons/linksicon.svg";
// import { ReactComponent as MySVG } from "../../assets/icons/go_pointer_top_icon.svg";
// import { ReactComponent as MySVG } from "../../assets/icons/7601886_top_arrow.svg";
import { ReactComponent as MySVG } from "../../assets/icons/7601886_top_arrow-update.svg";
// import { ReactComponent as MySVG } from '../../assets/icons/7601886_top_arrow-1.svg';
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import DismissButton from "../../assets/icons/subbcuketsbackarrow.svg";
import PopUpClose from "../../assets/icons/orangedismiss.svg";
import "react-toastify/dist/ReactToastify.css";
import "../../components/Bookmarks/Bookmarks.scss";
import BookmarksIcon from "../../assets/images/bookmarksIcon.svg";
import BookmarksImg1 from "../../assets/images/bookmarksimg1.png";
import BookmarksImg2 from "../../assets/images/bookmarksimg2.png";
import BookmarksImg3 from "../../assets/images/bookmarksimg3.png";
import BookmarksImg4 from "../../assets/images/bookmarksimg4.png";
import BookmarksImg5 from "../../assets/images/bookmarksimg5.png";
import BookmarksImg6 from "../../assets/images/bookmarksimg6.png";
import BookmarksShared from "../../assets/icons/bookmarksShared.svg";
import FilesSub from "../../assets/icons/subfilesorange.svg";
import ListviewIcon from "../../assets/icons/listBlackIcon.svg";
import GridviewIcon from "../../assets/icons/gridgreyIcon.svg";
import BookmarksSub from "../../assets/icons/subbookmarksorange.svg";
import NewBookmarksSub from "../../assets/icons/newBookmarksIcon.svg";
import BookmarkList from "../../components/Bookmark List/bookmarklist";
import UploadIcon from "../../assets/icons/uploadIcon.svg";
import SortMbl from "../../assets/icons/sortmbl.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollToTop from "react-scroll-to-top";
import Bookmarks from "../../components/Bookmarks/Bookmarks";
import BookmarksPopup from "../../components/BookmarksPopup/BookmarksPopup";
import CustomizePopup from "../../shades/cards/popupfinal";
import ShareBucket from "./ShareBucket";
import FilesPopup from "../../components/FilesPopup/FilesPopup";
import MoveModal from "./includes/MoveModal";
const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };

function BucketInner(props) {
  const navigate = useNavigate();
  const [subBucket, setSubBucket] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [bucketCreated, setBucketCreated] = useState(false);
  const [cardCreated, setCardCreated] = useState(false);
  const [bucketOperation, setBucketOperation] = useState("Add");
  const [selectedItems, setSelectedItems] = useState([]);
  const [bucketNameText, setBucketNameText] = useState("");
  const [bucketUpdateId, setBucketUpdateId] = useState(0);
  const [BucketSharePopup, setBucketSharePopup] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const MainbucketId = urlSearchParams.get("bucketId");
  const MainbucketName = urlSearchParams.get("name");
  const [CardSharePopup, setCardSharePopup] = useState(false);
  const [shareBucketId, setShareBucketId] = useState("");
  const [shareCardId,setShareCardId]=useState("");
  let [isOpens, setIsOpen] = useState(false);
  let [isOpenss, setIsOpens] = useState(false);
  const [isOpen] = useOutletContext();
  const [cardTitle, setCardTitle] = useState("");
  const [isListView, setIsListView] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [isListViewOpen, setIsListViewOpen] = useState(false);
  const [isGridViewOpen, setIsGridViewOpen] = useState(true);
  const [isListViewIcon, setIsListViewIcon] = useState(false);
  const [isGridViewIcon, setIsGridViewIcon] = useState(false);
  const [filepopup, setFilePopup] = useState(false);
  const [Custompopup, setCustomPopup] = useState(false);
  const [filepopupAnimation, setFilePopupAnimation] = useState(false);
  const [bookmarkpopupAnimation, setBookmarkPopupAnimation] = useState(false);
  const [bookmarkpopup, setBookmarkPopup] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isListBookmarksView, setIsListBookmarksView] = useState(true);
  const [isGridBookmarksView, setIsGridBookmarksView] = useState(true);
  const [isListBookmarksViewIcon, setIsListBookmarksViewIcon] = useState(false);
  const [isGridBookmarksViewIcon, setIsGridBookmarksViewIcon] = useState(false);
  const [isListBookmarksViewOpen, setIsListBookmarksViewOpen] = useState(false);
  const [isGridBookmarksViewOpen, setIsGridBookmarksViewOpen] = useState(true);
  const [titleState, setTitleState] = useState(true);
  const [filefilespopup, setFilefilesPopup] = useState(false);
  const [selectedBucketName, setSelectedBucketName] = useState("");
  const [selectedBucketId, setSelectedBucketId] = useState("");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [bookmarksfilespopup, setBookmarksfilesPopup] = useState(false);
  const [lastList, setLastList] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [buckets, setBuckets] = useState([]);
  const [totalCards, setTotalCards] = useState(0);
  const [bookmarkPopupCount, setBookmarkPopupCount] = useState(0);
  const [filePopupCount, setFilePopupCount] = useState(0);
  const [SharedToken, setSharedToken] = useState("");
  const [openMove, setOpenMove] = useState(false);
  const [PermissionState, setPermissionState] = useState("");
  const [moveModal,setMoveModal]=useState(false)
  const [subBucketToken, setSubBucketToken] = useState(true);
  const [appTheme,setAppTheme]=useState("");
  const[openCopyLink, setOpenCopyLink] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const [iOS, setiOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, [bucketCreated]);
  const closeSharePop = () => {
    console.log("close share pop");
    setBucketSharePopup(false);
  };
  const bucketPublicShare = (url) => {
    if(iOS){
      setOpenCopyLink(true);
      setPublicUrl(url);
    }else{
      const tempInput = document.createElement("input");
      tempInput.value = url;

      // Append the input element to the DOM
      document.body.appendChild(tempInput);

      // Select the input element's text and copy it to clipboard
      tempInput.select();
      document.execCommand("copy");

      // Remove the temporary input element
      document.body.removeChild(tempInput);
      
      toastify("success", "Public link is copied to clipboard");
    }
}
const closeCopyLinkModal = () => {
  setOpenCopyLink(false);
}
const handleCopyText = (url) => {
  var emailLink = document.querySelector('.publicUrlInput');
  console.log(emailLink,"copyEmailBtn"); 
  emailLink.select();
  var range = document.createRange();  
  range.selectNode(emailLink);  
  window.getSelection().addRange(range);  

  try {  
    // Now that we've selected the anchor text, execute the copy command  
    var successful = document.execCommand('copy');  
    var msg = successful ? 'successful' : 'unsuccessful';  
    console.log('Copy email command was ' + msg);  
    toastify("success", "Public link is copied to clipboard");
  } catch(err) {  
    console.log('Oops, unable to copy');  
    toastify("success", 'Oops, unable to copy');
  }  

  // Remove the selections - NOTE: Should use
  // removeRange(range) when it is supported  
  window.getSelection().removeAllRanges();  
  setOpenCopyLink(false);
  // setTimeout(() => {
  //   bucketUpdate();
  // }, 1000);
}
  const MoveCard = async () => {
    setMoveModal(true)
    setOpenMove(true);
  };
 
  const closeMove = () => {
    // setIsBucketOpen(false);
    // setSelectedItems("")
    setSelectedItems([])
    setMoveModal(false)
    setOpenMove(false);
    // setselectedBucketId("");
    // setSearchBucket("");
  };
  const closeCardSharePop = () => {
    console.log("close share pop");
    setCardSharePopup(false);
  };
  const closeCustomPopup = () => {
    setCustomPopup(false);
  };
  const handlefilefilesDrop = (event) => {
    event.preventDefault();

    const newFiles = Array.from(event.dataTransfer.files);
    setFiles(newFiles);
  };

  const handlefileDragOver = (event) => {
    event.preventDefault();
  };
  const handleFilefilesChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(newFiles);
  };
  const handleFilefilesPopup = () => {
    setFilefilesPopup(true);
  };
  const handlefileClickBrowse = () => {
    fileInputRef.current.click();
  };

  function closeFilefilesPopup() {
    setFilefilesPopup(false);
  }

  const handleIsListView = () => {
    setIsListView(false);
    setIsGridView(false);
    setIsListViewOpen(true);
    setIsGridViewOpen(false);
    setIsListViewIcon(true);
    setIsGridViewIcon(true);
  };

  const handleCustomPopup = (name, id) => {
    console.log(id, "inerrrrrrr bucket name");
    setSelectedBucketName(name);
    setSelectedBucketId(id);
    setCustomPopup(true);
  };

  const handleFilePopup = () => {
    setFilePopup(true);
    setFilePopupAnimation(true);
  };

  function closeFilePopup() {
    setFilePopupAnimation(false);
    setTimeout(() => {
      setFilePopup(false);
    });
  }

  const handleBookmarkPopup = () => {
    setBookmarkPopup(true);
    setBookmarkPopupAnimation(true);
  };

  function closeBookmarkPopup() {
    setBookmarkPopupAnimation(false);
    setTimeout(() => {
      setBookmarkPopup(false);
    });
  }

  const handlebookmarksfilesDrop = (event) => {
    event.preventDefault();

    const newFiles = Array.from(event.dataTransfer.files);
    setFiles(newFiles);
  };

  const handlebookmarksDragOver = (event) => {
    event.preventDefault();
  };
  const handleBookmarkfilesChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(newFiles);
  };
  const handleBookmarksfilesPopup = () => {
    setBookmarksfilesPopup(true);
  };
  const handlebookmarkClickBrowse = () => {
    fileInputRef.current.click();
  };

  function closeBookmarksfilesPopup() {
    setBookmarksfilesPopup(false);
  }

  const handleIsGridView = () => {
    setIsListView(false);
    setIsGridView(true);
    setIsListViewOpen(false);
    setIsGridViewOpen(true);
    // setIsListViewIcon(false)
  };

  const handleIsListViewIcon = () => {
    setIsListView(true);
    setIsGridView(false);
    setIsListViewOpen(false);
    setIsGridViewOpen(true);
    setIsListViewIcon(false);
    // setIsGridViewIcon(false)
  };

  const handleIsGridViewIcon = () => {
    setIsListView(true);
    setIsGridView(true);
    setIsListViewOpen(false);
    setIsGridViewOpen(true);
    setIsListViewIcon(false);
    setIsGridViewIcon(false);
  };

  const handleIsGridBookmarksView = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(true);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewOpen(false);
    setIsListBookmarksViewIcon(false);
  };

  const handleIsListBookmarksView = () => {
    setIsListBookmarksView(false);
    setIsGridBookmarksView(false);
    setIsListBookmarksViewOpen(true);
    setIsGridBookmarksViewOpen(false);
    setIsListBookmarksViewIcon(true);
    setIsGridBookmarksViewIcon(true);
  };

  const handleIsListBookmarksViewIcon = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(false);
    setIsListBookmarksViewOpen(false);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewIcon(false);
    // setIsGridViewIcon(true)
  };

  const handleIsGridBookmarksViewIcon = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(true);
    setIsListBookmarksViewOpen(false);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewIcon(false);
    setIsGridBookmarksViewIcon(false);
  };

  const bucketId = useParams();
  console.log(bucketId, "bucketId");
  function closeModal() {
    setIsOpen(false);
  }

  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }

  const filterFilesDropDown = [
    { name: "File Name", href: "#" },
    { name: "Bucket Name", href: "#" },
    { name: "Upload Date", href: "#" },
    { name: "Size", href: "#" },
  ];

  const [setFiltersSelectText] = useState(filterFilesDropDown[0].name);

  const handleFilterFilesText = (text) => {
    setFiltersSelectText(text);
  };

  const filterDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
    // { name: 'Manual', href: '#' },
  ];

  let cardFilterv = "";
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem("cardFilter", "Created_Date");
    cardFilterv = "Created_Date";
  } else {
    cardFilterv = localStorage.getItem("cardFilter");
  }
  console.log(cardFilterv, "cardFilterv");
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      // setIsLoadings(true)
      try {
        const response = await axios.post(
          "https://api.zimzee.com/api/cards/cardList-by-bucketId",
          {
            bucketId: `${bucketId.id}`,
            filterValue: cardFilterDefault,
            page: 1,
            limit: 1,
            type: "BookMark",
            tagId:"",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, "bookmarks response");
        setBookmarkPopupCount(response?.data?.data?.totalCards);
        // setIsLoadings(false)
      } catch (error) {
        console.error(error);
      }
    };
    const fetchDatas = async () => {
      const token = localStorage.getItem("token");
      // setIsLoadings(true)
      try {
        const response = await axios.post(
          "https://api.zimzee.com/api/cards/cardList-by-bucketId",
          {
            bucketId: `${bucketId.id}`,
            filterValue: cardFilterDefault,
            page: 1,
            limit: 1,
            type: "File",
            tagId:"",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, "bookmarks response");
        setFilePopupCount(response?.data?.data?.totalCards);
        // setIsLoadings(false)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchDatas();
  },[]);
  useEffect(() => {
    // api url
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bookmarkpopup = urlSearchParams.get("bookmarkpopup");
    const shared = urlSearchParams.get("shared");
    const permission = urlSearchParams.get("permission");
    if (shared === "true") {
      setSharedToken(true);
    }
    if (permission) {
      setPermissionState(permission);
    }
    // console.log(bookmarkpopup,"bookmarkpopup");
    // bookmarkpopup == 1 ? setBookmarkPopup(true) : "";
    if (bookmarkpopup) {
      setBookmarkPopup(true);
    }

    const filepopups = urlSearchParams.get("filepopup");
    if (filepopups) {
      setFilePopup(true);
    }
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API}buckets/single/${bucketId.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setIsLoading(false);
        setSubBucket(response?.data?.data);
        console.log(subBucket);
      })
      .catch(function (error) {
        console.log(error);
      });
    const fetchData = async () => {
      setIsLoadings(true);
      axios
        .post(
          `${process.env.REACT_APP_API}cards/cardList-by-bucketId`,
          {
            bucketId: `${bucketId.id}`,
            filterValue: cardFilterDefault,
            page: currPage,
            limit: 8,
            type: "Card",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setIsLoading(false);
          console.log(response.data.data.cardsList, "<<<");
          if (!response.data.data.cardsList.length) {
            setLastList(true);
            setIsLoadings(false);
            return;
          }
          setPrevPage(currPage);
          // setUserList([...userList, ...response.data.data]);
          setCardList([...cardList, ...response.data.data.cardsList]);
          setTotalCards(response.data.data.totalCards);
          setIsLoadings(false);
        })
        .catch(function (error) {
          console.log(error);
          if(error?.response?.data?.statusCode){
            if(error?.response?.data?.statusCode == 404){
              navigate(`/notFound`)
            }
          }
        });
    };
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      console.log(
        scrollTop,
        clientHeight,
        scrollHeight,
        "scrollTop, clientHeight, scrollHeight window"
      );
      console.log(
        parseInt(scrollTop),
        parseInt(clientHeight),
        parseInt(scrollHeight),
        "scrollTop, clientHeight, scrollHeight window"
      );
      if (
        parseInt(scrollTop) + parseInt(clientHeight) ===
        parseInt(scrollHeight)
      ) {
        setCurrPage(currPage + 1);
      }
    };

    if (!lastList && prevPage !== currPage) {
      fetchData();
      // console.log(listInnerRef,"listInnerRef");
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    bucketCreated,
    cardCreated,
    currPage,
    lastList,
    prevPage,
    cardList,
    isLoadings,
  ]);

  const reorder = (arr, columns) => {
    // READ HERE
    // this is the magic
    // re-order the array so the "cards" read left-right
    // cols === CSS column-count value

    const cols = columns;
    const out = [];
    let col = 0;
    while (col < cols) {
      for (let i = 0; i < arr.length; i += cols) {
        let _val = arr[i + col];
        if (_val !== undefined) out.push(_val);
      }
      col++;
    }
    console.log(out, "out");
    setCardList(out);
  };

  function bucketUpdate() {
    setBucketCreated(!bucketCreated);
  }

  function changeTxt(event) {
    setBucketNameText(event.target.value);
  }

  const updateBucket = async (bucketId, pin, action) => {
    if (action != "pinn") {
      const focusItems = subBucket?.subBuckets.filter((item) => item.isFocused);
      console.log(focusItems);
      const selectedItem = subBucket?.subBuckets.find(
        (item) => item._id === bucketId
      );
      console.log(selectedItem, "selectedItem");
      if (!selectedItem.isFocused && focusItems.length >= 2) {
        // User cannot select more than two focus items
        // alert("You can't select more than two focus items!");
        toastify("error", "A maximum of two focus buckets is allowed");
        return;
      }
      // return;
    }
    setIsLoading(true);
    let bucketAction =
      action == "pinn"
        ? { bucketId: bucketId, pinned: pin }
        : { bucketId: bucketId, isFocused: pin };
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}buckets/update`,
        bucketAction,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //  toastify("success", response.data.message);
      bucketUpdate();
      //  setIsLoading(false);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleDeleteClick = (id) => {
    setIsOpen(true);
    console.log(id);
    setBucketUpdateId(id);
  };
  const handleCardTitle = () => {
    const token = localStorage.getItem("token");
    try {
      const response = axios.patch(
        "https://api.zimzee.com/api/buckets/update",
        { bucketId: bucketId.id, name: cardTitle },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response) {
        setTitleState(true);
        bucketUpdate();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}buckets/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response");
      //  toastify("sucess", response.message );
      toastify("success", response.data.message);
      axios
        .get(`${process.env.REACT_APP_API}buckets/single/${bucketId.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setIsLoading(false);
          setSubBucket(response?.data?.data);
          console.log(subBucket);
        })
        .catch(function (error) {
          console.log(error);
        });
      // setIsLoading(false);
      //  setBucketNameText(response.data.data.name);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
    setIsOpen(false);
  };

  const handleClick = async (id) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}buckets/single/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data.name, "response");
      setBucketNameText(response.data.data.name);
      console.log("heeloo bucket anem");
      setTimeout(() => {
        document.getElementsByName("name")[0].focus();
      }, 100);
    } catch (error) {
      console.error(error);
    }
    console.log("Button clicked!");
    console.log(id);
    setPopup(true);
    setBucketOperation("Update Sub");
    setBucketUpdateId(id);
  };
  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareBucketId(id);
    setBucketSharePopup(true);
  };
  const handleCardSharePop = (id) => {
    console.log(id, "share card id");
    setShareCardId(id);
    setCardSharePopup(true);
  };
  // const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
  const [filtersText, setFiltersText] = useState(
    cardFilterv == "Created_Date"
      ? filterDropDown[0].name
      : cardFilterv == "Descending_Order"
      ? filterDropDown[3].name
      : cardFilterv == "Ascending_Order"
      ? filterDropDown[2].name
      : cardFilterv == "Recent_Activity"
      ? filterDropDown[1].name
      : ""
  );

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  console.log("Selected Items:", selectedItems);
  const deleteCard = async () => {
    setIsOpens(true);
  };
  const handleDeleteCard = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: selectedItems },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      setSelectedItems([]);
      closeCardModal();
      cardUpdate();
    } catch (error) {
      console.error(error);
      closeCardModal();
      setIsLoading(false);
      toastify("error", error.response.data.message);
    }
  };

  const handleCardClick = (text) => {
    if (text == "Select All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (!checkbox.checked) {
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItems((prevSelectedItems) => [
              ...prevSelectedItems,
              checkbox.value,
            ]);
          }
        } else {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    } else if (text == "Unselect All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    }
  };

  const handleFilterText = (text) => {
    // setFiltersText(text);
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues =
      text == "Created Date"
        ? "Created_Date"
        : text == "Descending Order"
        ? "Descending_Order"
        : text == "Ascending Order"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : "";
    localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    const token = localStorage.getItem("token");
    cardUpdate();
    // axios.post(`${process.env.REACT_APP_API}cards/cardList-by-bucketId`, {
    //     bucketId:`${bucketId.id}`,
    //     filterValue:filterValues,

    //   },{
    //     headers: {
    //     Authorization: `Bearer ${token}`,
    //   }
    //   })
    //   .then(function (response) {
    //     setIsLoading(false);
    //     // setCardList(response.data.data);
    //     setCardList(response.data.data.cardsList);
    //     setTotalCards(response.data.data.totalCards);
    //     // reorder(response.data.data);
    //     // setBucketList(response.data.data.bucketList);
    //     // setTotalBuckets(response.data.data.total);
    //     console.log(cardList,"cardList");
    //   })
    // .catch(function (error) {
    //   console.log(error);
    // });
  };
  const bucketNav = [
    // {icon:faPencil, name: 'Edit Name', href: '#' },
    // { icon: faNoteSticky, name: "Create New Card", href: "#" },
    { icon: faTrash, name: "Select All", href: "#" },
    { icon: faTrash, name: "Unselect All", href: "#" },
    // {icon:faFolder, name: 'Customize New Card', href: '#' },
  ];

  const createBucket = async (name) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.zimzee.com/api/buckets/add/",
        { name, userId: userData._id, parentId: bucketId.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const createSubBucket = async (event) => {
    event.preventDefault();
    const name = event.target.name.value; // Get the name value from the form
    await createBucket(name);
    setPopup(false);
  };

  function createSub() {
    // setPopup(false);
    setBucketOperation("subbucket");
    setBucketNameText("");
    setPopup(true);
    setTimeout(() => {
      document.getElementsByName("name")[0].focus();
    }, 100);
  }
  const handleOnChangeTitle = (e) => {
    setCardTitle(e.target.value);
  };
  function closePopup() {
    setPopup(false);
  }
  const handleEditTitle = () => {
    setTitleState(false);
    setCardTitle(subBucket?.name);
    setTimeout(() => {
      document.getElementsByClassName("bucketeditname-content")[0].focus();
    }, 100);
  };
  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log("Child clicked");
  };

  function cardUpdate() {
    // setBucketCreated(true);
    setLastList(false);
    setCurrPage(1);
    setPrevPage(0);
    setCardList([]);
    setCardCreated(!cardCreated);
  }

  function bucketUpdate() {
    // setBucketCreated(true);
    setBucketCreated(!bucketCreated);
  }

  function getUrl(type) {
    if (type === "list") {
      return `/app/buckets/${bucketId.id}`;
    } else {
      return `/app/cardlist`;
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <div className="subBuckets-whole listingCards subBucketInner">
          <div className="cards">
            <div className="card-header flex flex-col">
              <div className="cardHeaderContent">
              <div className="bucketNameTag">
                  <p className="subBucket-content">
                    <a href={`/app/buckets`}>My Buckets</a>{" "}
                    <span className="uxtext-content">
                      /&nbsp;
                      <a href={`/app/buckets/${MainbucketId}`}>
                        {MainbucketName}
                      </a>{" "}
                      /&nbsp;
                      <a
                        href={`/app/sub-buckets/${subBucket._id}?bucketId=${MainbucketId}&name=${MainbucketName}`}
                      >
                        {subBucket.name}
                      </a>
                    </span>
                  </p>
                </div>
                <div className="subbucketheaderdesktop-section">
                  <div className="subbucket-displaycontent">
                    <div className="flex items-center">
                      {titleState ? (
                        <h4 className="bucketName-content">
                          {subBucket?.name}
                        </h4>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="bucketeditname-content"
                            value={cardTitle}
                            onChange={handleOnChangeTitle}
                            style={{
                              fontSize: "18px",
                              outline: "none",
                              border: "none",
                              padding: "0 10px",
                            }}
                          />
                        </>
                      )}
                      {!subBucket.default ? (
                        <div className="bucketActions flex items-center">
                          <span>
                            {titleState ? (
                              <img
                                className="subbuckets-dark"
                                src={EditIcon}
                                alt="editIcon"
                                onClick={handleEditTitle}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ height: "22px", cursor: "pointer" }}
                                icon={faCheck}
                                onClick={handleCardTitle}
                                color="gray"
                              />
                            )}
                          </span>
                          {/* <span>
                            <img
                              className="ml-[30px] mr-[30px]"
                              src={DeleteIcon}
                              alt="DeleteIcon"
                            />
                          </span> */}
                          <span className="ml-[30px]">
                            
                          </span>
                          <div>
                            {/* <button className="buttonfilespadding-content text-[16px]" >
                                          <span className="filescardbutton-context flex"><span><img className="pl-2 pr-2" src={FilesOrange} alt="filesOrange" /><div id="overlay"></div></span>Files</span>
                                        </button> */}
                          </div>
                          {BucketSharePopup && (
                            <ShareBucket
                              shareBucketId={shareBucketId}
                              closeSharePop={closeSharePop}
                              bucketUpdate={bucketUpdate}
                              bucketPublicShare={bucketPublicShare}
                            />
                          )}
                         

                          {filepopup && (
                            <div
                              className="filescreationpopup relative !z-[999]"
                              style={{ height: "100%" }}
                              onClick={closeFilePopup}
                            >
                              <div
                                className={
                                  filepopupAnimation
                                    ? "subfiles-section subbucketsfiles-wholesection"
                                    : "subfiles-section-menu-close subbucketsfiles-wholesection"
                                }
                                onClick={handleChildClick}
                              >
                                <div>
                                  <img
                                    className="dismissfiles-content"
                                    src={DismissButton}
                                    alt="dismissButton"
                                    onClick={closeFilePopup}
                                  />
                                </div>
                                <FilesPopup bucketName={subBucket.name} />
                              </div>
                            </div>
                          )}

                          {/* <div>
                                    <button className="buttonbookpadding-content text-[16px]" >
                                      <span className="filescardbutton-context flex"><span><img className="pl-2 pr-2" src={BookmarksOrange} alt="filesOrange" /></span>Bookmarks</span>
                                    </button>
                                  </div> */}
                          {bookmarkpopup && (
                            <div
                              className="filescreationpopup relative !z-[999]"
                              style={{ height: "100%" }}
                              onClick={closeBookmarkPopup}
                            >
                              <div
                                className={
                                  bookmarkpopupAnimation
                                    ? "subfiles-section subbucketsfiles-wholesection"
                                    : "subfiles-section-menu-closebookmark subbucketsfiles-wholesection"
                                }
                                onClick={handleChildClick}
                              >
                                <div>
                                  <img
                                    className="dismissfiles-content"
                                    src={DismissButton}
                                    alt="dismissButton"
                                    onClick={closeBookmarkPopup}
                                  />
                                </div>
                                <BookmarksPopup bucketName={subBucket.name} />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {
                      !subBucket.default?
                      <div
                      className="cardsjustify-end inviteBtn"
                      onClick={() => handleSharePop(bucketId.id)}
                    >
                      <a
                        style={{
                          filter:
                            "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                        }}
                        className="btn-invited rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg"
                        href="#"
                      >
                        @ Invite People
                      </a>
                    </div> : ""
                    }
                    
                  </div>
                </div>
                <div className="subbucketheadermbl-section ">
                <div className="subbucket-displaycontent">
                    <div className="flex items-center">
                      {titleState ? (
                        <h4 className="bucketName-content">
                          {subBucket?.name}
                        </h4>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="bucketeditname-content"
                            value={cardTitle}
                            onChange={handleOnChangeTitle}
                            style={{
                              fontSize: "18px",
                              outline: "none",
                              border: "none",
                              padding: "0 10px",
                            }}
                          />
                        </>
                      )}
                      {!subBucket.default ? (
                        <div className="bucketActions flex items-center">
                          <span>
                            {titleState ? (
                              <img
                                className="subbuckets-dark"
                                src={EditIcon}
                                alt="editIcon"
                                onClick={handleEditTitle}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ height: "22px", cursor: "pointer" }}
                                icon={faCheck}
                                onClick={handleCardTitle}
                                color="gray"
                              />
                            )}
                          </span>
                          {/* <span>
                            <img
                              className="ml-[30px] mr-[30px]"
                              src={DeleteIcon}
                              alt="DeleteIcon"
                            />
                          </span> */}
                          <span className="ml-[30px]">
                           
                          </span>
                          <div>
                            {/* <button className="buttonfilespadding-content text-[16px]" >
                                          <span className="filescardbutton-context flex"><span><img className="pl-2 pr-2" src={FilesOrange} alt="filesOrange" /><div id="overlay"></div></span>Files</span>
                                        </button> */}
                          </div>
                          {BucketSharePopup && (
                            <ShareBucket
                              shareBucketId={shareBucketId}
                              closeSharePop={closeSharePop}
                              bucketUpdate={bucketUpdate}
                              bucketPublicShare={bucketPublicShare}
                            />
                          )}
                         

                          {filepopup && (
                            <div
                              className="filescreationpopup relative !z-[999]"
                              style={{ height: "100%" }}
                              onClick={closeFilePopup}
                            >
                              <div
                                className={
                                  filepopupAnimation
                                    ? "subfiles-section subbucketsfiles-wholesection"
                                    : "subfiles-section-menu-close subbucketsfiles-wholesection"
                                }
                                onClick={handleChildClick}
                              >
                                <div>
                                  <img
                                    className="dismissfiles-content"
                                    src={DismissButton}
                                    alt="dismissButton"
                                    onClick={closeFilePopup}
                                  />
                                </div>
                                <FilesPopup bucketName={subBucket.name} />
                              </div>
                            </div>
                          )}

                          {/* <div>
                                    <button className="buttonbookpadding-content text-[16px]" >
                                      <span className="filescardbutton-context flex"><span><img className="pl-2 pr-2" src={BookmarksOrange} alt="filesOrange" /></span>Bookmarks</span>
                                    </button>
                                  </div> */}
                          {bookmarkpopup && (
                            <div
                              className="filescreationpopup relative !z-[999]"
                              style={{ height: "100%" }}
                              onClick={closeBookmarkPopup}
                            >
                              <div
                                className={
                                  bookmarkpopupAnimation
                                    ? "subfiles-section subbucketsfiles-wholesection"
                                    : "subfiles-section-menu-closebookmark subbucketsfiles-wholesection"
                                }
                                onClick={handleChildClick}
                              >
                                <div>
                                  <img
                                    className="dismissfiles-content"
                                    src={DismissButton}
                                    alt="dismissButton"
                                    onClick={closeBookmarkPopup}
                                  />
                                </div>
                                <BookmarksPopup bucketName={subBucket.name} />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {
                      !subBucket.default?
                      <div
                      className="cardsjustify-end inviteBtn"
                      onClick={() => handleSharePop(bucketId.id)}
                    >
                      <a
                        style={{
                          filter:
                            "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                        }}
                        className="btn-invited rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg"
                        href="#"
                      >
                        @ Invite People
                      </a>
                    </div> : ""
                    } */}
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[3px] mb-[3px] text-[14px] lg:text-[17px]">
              <a className="flex items-center" href="/">
                <img
                  src={CalenderIcon}
                  alt="calenderIcon"
                  className="w-[22px] dark-mode-icon"
                />{" "}
                <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">
                  Created{" "}
                  {moment(subBucket.createdAt).format("MMM D, YYYY @ hh:mm A")}
                </span>
              </a>{" "}
            </div>
            <div className="subbucketheadermbl-section gfhgfhgf">
                {
                  !subBucket.default?
                    <div className="cardsjustify-end inviteBtn">
                      <a
                        style={{
                          filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                        }}
                        className="btn-invited rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg"
                        href="#"
                        onClick={() => handleSharePop(bucketId.id)}
                      >
                        @ Invite People
                      </a>
                    </div> : ""
                }
            </div>

            <hr />
            {/* <div className="cardHeaderDetails mt-3">
                  <ul className="flex ">
                    <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttoncardpadding-content text-[16px] bg-[#FF6600] text-white">
                      <span className="savecardbutton-context">Sub Buckets</span>
                    </button>
                    <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttoncardpadding-content text-[16px] bg-[#FF6600] text-white">
                      <span className="savecardbutton-context">Files</span>
                    </button>
                    <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttoncardpadding-content text-[16px] bg-[#FF6600] text-white">
                      <span className="savecardbutton-context">Cards</span>
                    </button>
                    <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttoncardpadding-content text-[16px] bg-[#FF6600] text-white">
                      <span className="savecardbutton-context">Bookmarks</span>
                    </button>
                  </ul>
                </div> */}
            {Custompopup && (
              <div
                className="filesbucketuploadpopup relative !z-[999]"
                style={{ height: "100%" }}
                onClick={closeCustomPopup}
              >
                <div
                  className="subfilesbucketupload-section"
                  onClick={handleChildClick}
                >
                  <div onClick={closeCustomPopup}>
                    <img
                      className="dismissSubfiles-content"
                      src={PopUpClose}
                      alt="dismissButton"
                    />
                  </div>
                  <CustomizePopup
                    selectedBucketName={selectedBucketName}
                    selectedBucketId={selectedBucketId}
                    closeCustomPopup={closeCustomPopup}
                    bucketUpdate={bucketUpdate}
                  />
                </div>
              </div>
            )}
            {!subBucket.default ? (
              <div className="subBucketsHolder">
                <div className="subbucket-displaycontent">
                  <div className="subBuckets flex items-center">
                    {/* <h4 className="subbucketstext-content">Sub Buckets</h4>
                    <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                      {subBucket?.subBuckets?.length}
                    </span> */}
                  </div>
                  <div className="flex 4 cardsjustify-end"></div>
                </div>
                <div className="subBucketsList">
                  <div className={`BucketListHolder-content`}>
                    
                  </div>
                </div>
                <div className="cardsjustify-end justify-end">
                  <div className="fileSub-imgcontent" onClick={handleFilePopup}>
                    <span class="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px] file-count-popup">{filePopupCount}</span>
                    <img
                      className="filesub-bgcontent"
                      src={FilesSub}
                      alt="filesSub"
                    />
                  </div>
                </div>
                <div className="cardsjustify-end justify-end">
                  <div
                    className="bookmarksSub-imgcontent"
                    onClick={handleBookmarkPopup}
                  >
                    <span class="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px] bookmarks-count-popup">{bookmarkPopupCount}</span>
                    <img
                      className="filesub-bgcontent"
                      src={NewBookmarksSub}
                      alt="bookmarksSub"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <div className="cards-filter  pt-[80px] flex justify-between">
                    <div className="flex items-center">
                        <div className="card-count flex items-center">
                            <h4 className="cardssubBuckets-content">Files</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{cardList?.length}</span>
                        </div>
                        <Menu as="div" className="relative px-2">
                            <Menu.Button
                                className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 "
                            >
                                <img className="threedots-content" src={ThreeDots} alt="threeDots" />
                            </Menu.Button>
                            <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {bucketNav.map((item) => (
                                        <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <a
                                            href={item.href}
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block py-2 px-4 text-sm text-gray-700'
                                            )}
                                            >
                                                <span className="pr-2"><FontAwesomeIcon className="text-[#797979]" icon={item.icon} /></span>
                                            <span className="text-black">{item.name}</span>
                                            </a>
                                        )}
                                        </Menu.Item>
                                    ))}
                                    </Menu.Items>
                                </Transition>
                        </Menu>
                        <img src={DeleteIcon} alt="deleteIcon" className="px-3" onClick={()=>deleteCard()}/>
                        <Transition appear show={isOpenss} as={Fragment}>
                          <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                              <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-out duration-300"
                                  enterFrom="opacity-0 scale-95"
                                  enterTo="opacity-100 scale-100" 
                                  leave="ease-in duration-200"
                                  leaveFrom="opacity-100 scale-100"
                                  leaveTo="opacity-0 scale-95"
                                >
                                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                    <Dialog.Title
                                      as="h3"
                                      className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                      Delete
                                    </Dialog.Title>
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-500">
                                        Are you sure you want to delete?
                                      </p>
                                    </div>

                                    <div className="mt-4">
                                      <button style={{background:'#FF6600',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => handleDeleteCard()}
                                      >
                                        Yes
                                      </button>
                                      <button style={{background:'#000',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                                        type="button"
                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeCardModal}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </Dialog.Panel>
                                </Transition.Child>
                              </div>
                            </div>
                          </Dialog>
                        </Transition>
                    </div>
                    <div className="flex">
                    <div className="filterHolder flex items-center justify-end">
                        <div className="filter display-content"> 
                        {isListView &&
                          <div onClick={handleIsListView}>
                            <a className="viewGrid" href="#">                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} style={{color:'#000000'}}  alt="listView" />
                            </a>
                          </div>
                        }
                        {isGridView &&
                          <div onClick={handleIsGridView}>
                            <a className="viewGrid" href="#">                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} alt="compactIcon" />
                            </a>
                          </div>
                        }
                        </div>
                        
                        <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                                  >
                                  <strong className="createdtext-content pr-4">Upload Date</strong>
                                  <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                              </a>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                              {filterFilesDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (

                                    <a
                                      onClick={()=>{handleFilterFilesText(item.name)}}
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                    </div>
                    </div>
                </div>  */}

            {/* {isListViewOpen &&
                  <div>
                    <div className="flex fileswhole-section">
                      <div className="file-section fileheading-content filespace-content">File name</div>
                      <div className="bucket-section fileheading-content">Bucket name</div>
                      <div className="upload-section fileheading-content">Upload date</div>
                      <div className="sharedwith-section fileheading-content">Shared with</div>
                      <div className="size-section fileheading-content">Size</div>
                      <div className="action-section fileheading-content">Action</div>
                    </div>
                    <hr/>
                    <div className="flex spacefiles-content">
                      <div className="file-section flex"><input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" /><img className="pl-3 pr-3" src={Document} alt="document" /><span className="filesection-content">UX/UI Design Trends & Fashion</span></div>
                      <div className="bucket-section filesection-content">Fashion Trends 2022 Sketch</div>
                      <div className="upload-section filesection-content">Dec 4, 2021 @ 4:30 PM</div>
                      <div className="sharedwith-section">
                        <div className="profile-imgcontent">
                          <img src={Profile1} alt="profile1" />
                          <img src={Profile2} alt="profile2" />
                        </div>
                      </div>
                      <div className="size-section filesection-content">14 MB</div>
                      <div className="action-section flex">
                        <img className="tooltip-icon mr-3" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-3" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-3" src={SubTag} alt="subTag"/>
                        <img className="mr-3" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon mr-3" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                        <img src={ThreeDots} alt="threeDots" />
                      </div>
                    </div>
                    <div className="flex spacefiles-content">
                      <div className="file-section flex"><input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" /><img className="pl-3 pr-3" src={PDF} alt="PDF" /><span className="filesection-content">Fashion year - '23</span></div>
                      <div className="bucket-section filesection-content">Ganesh's fashion Bucket</div>
                      <div className="upload-section filesection-content">Dec 4, 2021 @ 4:30 PM</div>
                      <div className="sharedwith-section">-</div>
                      <div className="size-section filesection-content">85 MB</div>
                      <div className="action-section flex">
                        <img className="tooltip-icon mr-3" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-3" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-3" src={SubTag} alt="subTag"/>
                        <img className="mr-3" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon mr-3" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                        <img src={ThreeDots} alt="threeDots" />
                      </div>
                    </div>
                    <div className="flex spacefiles-content">
                      <div className="file-section flex"><input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" /><img className="pl-3 pr-3" src={ImageFolder} alt="ImageFolder" /><span className="filesection-content">UX/UI Design Trends & Fashion</span></div>
                      <div className="bucket-section filesection-content">Fashion Trends 2022 Sketch</div>
                      <div className="upload-section filesection-content">Dec 4, 2021 @ 4:30 PM</div>
                      <div className="sharedwith-section">-</div>
                      <div className="size-section filesection-content">250 MB</div>
                      <div className="action-section flex">
                        <img className="tooltip-icon mr-3" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-3" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-3" src={SubTag} alt="subTag"/>
                        <img className="mr-3" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon mr-3" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                        <img src={ThreeDots} alt="threeDots" />
                      </div>
                    </div>
                    <div className="flex spacefiles-content">
                      <div className="file-section flex"><input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" /><img className="pl-3 pr-3" src={Document} alt="document" /><span className="filesection-content">UX/UI Design Trends & Fashion</span></div>
                      <div className="bucket-section filesection-content">Fashion Trends 2022 Sketch</div>
                      <div className="upload-section filesection-content">Dec 4, 2021 @ 4:30 PM</div>
                      <div className="sharedwith-section">
                        <div className="profile-imgcontent">
                          <img src={Profile1} alt="profile1" />
                          <img src={Profile2} alt="profile2" />
                        </div>
                      </div>
                      <div className="size-section filesection-content">14 MB</div>
                      <div className="action-section flex">
                        <img className="tooltip-icon mr-3" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-3" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-3" src={SubTag} alt="subTag"/>
                        <img className="mr-3" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon mr-3" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                        <img src={ThreeDots} alt="threeDots" />
                      </div>
                    </div>
                    <div className="flex spacefiles-content">
                      <div className="file-section flex"><input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" /><img className="pl-3 pr-3" src={PDF} alt="PDF" /><span className="filesection-content">Fashion year - '23</span></div>
                      <div className="bucket-section filesection-content">Ganesh's fashion Bucket</div>
                      <div className="upload-section filesection-content">Dec 4, 2021 @ 4:30 PM</div>
                      <div className="sharedwith-section">-</div>
                      <div className="size-section filesection-content">85 MB</div>
                      <div className="action-section flex">
                        <img className="tooltip-icon mr-3" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-3" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-3" src={SubTag} alt="subTag"/>
                        <img className="mr-3" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon mr-3" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                        <img src={ThreeDots} alt="threeDots" />
                      </div>
                    </div>
                  </div>
                } 

                {isGridViewOpen &&
                  <div className="filescard-section md:masonry-2-col lg:masonry-4-col">
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-2">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={Document} alt="document" />
                          <h3 className="design-textcontent">UX/UI Design Trends & Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage1} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-5">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={PDF} alt="pdf" />
                          <h3 className="design-textcontent">Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage2} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-2">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={ImageFolder} alt="imageFolder" />
                          <h3 className="design-textcontent">UX/UI Design Trends & Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage2} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-2">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={Document} alt="document" />
                          <h3 className="design-textcontent">UX/UI Design Trends & Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage1} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-5">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={PDF} alt="pdf" />
                          <h3 className="design-textcontent">Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage2} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
                      <div className="flex justify-between w-full pt-3 pb-1">
                        <div className="inputCheck">
                          <input type="checkbox" />
                        </div>                        
                      </div>
                      <div className="cardHeader flex items-center pb-5">
                        <div className="Cardtitle flex">
                          <img className="mr-2" src={PDF} alt="pdf" />
                          <h3 className="design-textcontent">Fashion year - '23</h3>
                        </div>
                        <div className="iconsRight flex iconsalign-content">
                          <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
                        </div>
                      </div>
                      <div className="cardContent w-full">
                        <img className="w-full" src={CardImage2} alt="projectImg1" />
                      </div>
                      <div className="flex">
                        <div>                          
                          <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                          <div className="uploadedcards-content pb-2">Uploaded on Dec 4, 2021 @ 4:30 PM</div>
                        </div>
                        <div className="profile-displaycontent">
                          <div className="profile-imgcontent">
                            <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /><br/>
                          </div>
                          <p className="sharesright-content">0+ Shares</p>
                        </div>
                      </div>
                      <div className="flex cardsicon-content">
                        <img className="tooltip-icon mr-11" src={ClipBoard} alt="clipboardNotes" style={{width:"20px"}}/>
                        <img className="mr-11" src={SubStar} alt="subStar" style={{width:"20px"}} />
                        <img className="subtags mr-11" src={SubTag} alt="subTag"/>
                        <img className="mr-11" src={SubShare} alt="subShare" />
                        <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{width:"20px"}} />
                      </div>
                    </div>
                  </div>
                } */}

            <div className="subbucketheaderdesktop-section">
              <div className="cards-filter cardsspacing-content pt-[26px] flex justify-between">
                <div className="flex items-center">
                  <div className="card-count flex items-center">
                    <h4 className="cardssubBuckets-content">Cards</h4>
                    <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                      {totalCards}
                    </span>
                  </div>
                  <Menu as="div" className="relative px-2">
                    <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                      {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                      <img
                        className="threedots-content"
                        src={ThreeDots}
                        alt="threeDots"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {bucketNav.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                                onClick={() => {
                                  handleCardClick(item.name);
                                }}
                              >
                                <span className="pr-2">
                                  <FontAwesomeIcon
                                    className="text-[#797979]"
                                    icon={item.icon}
                                  />
                                </span>
                                <span className="text-black">{item.name}</span>
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {selectedItems.length > 0 ?
                <img
                src={appTheme==="light"?DeleteIcon:LightDelete}
                alt="deleteIcon"
                className="px-3"
                onClick={() => deleteCard()}
              />
              :
              <img
              src={appTheme==="light"?LightDelete:DeleteIcon}
              alt="deleteIcon"
              className="px-3"
              // onClick={() => deleteCard()}
            />
              }
                  <Transition appear show={isOpenss} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeCardModal}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                      </Transition.Child>

                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                Delete
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Are you sure you want to delete?
                                </p>
                              </div>

                              <div className="mt-4">
                                <button
                                  style={{
                                    background: "#FF6600",
                                    width: "80px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "50px",
                                  }}
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={() => handleDeleteCard()}
                                >
                                  Yes
                                </button>
                                <button
                                  style={{
                                    background: "#000",
                                    width: "80px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "50px",
                                  }}
                                  type="button"
                                  className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={closeCardModal}
                                >
                                  No
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                  {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
                  <img src={Arrow} alt="arrow" className="px-3" onClick={MoveCard} />
                </div>
                <div className="flex">
                  <div className="filterHolder flex items-center justify-end">
                    {/* <div className="filter display-content"> 
                            <a className="viewGrid" href={getUrl('list')}>                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                            </a>
                            <a className="viewGrid" href={getUrl('grid')}>                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a>
                        </div> */}

                    <span className="sorttext-content mx-3 dark:text-[#acacac]">
                      Sort By
                    </span>
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <a
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <strong className="createdtext-content pr-4">
                              {filtersText}
                            </strong>
                            <FontAwesomeIcon
                              style={{ fontSize: "12px", color: "#707070" }}
                              icon={faChevronDown}
                            />
                          </a>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filterDropDown.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={() => {
                                    handleFilterText(item.name);
                                  }}
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="subbucketheadermbl-section">
              <div className="cards-filter cardsspacing-content pt-[30px] flex justify-between">
                <div className="flex items-center">
                  <div className="card-count flex items-center">
                    <h4 className="cardssubBuckets-content">Cards</h4>
                    <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                      {totalCards}
                    </span>
                  </div>
                  <Menu as="div" className="relative px-2">
                    <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                      {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                      <img
                        className="threedots-content"
                        src={ThreeDots}
                        alt="threeDots"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {bucketNav.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                                onClick={() => {
                                  handleCardClick(item.name);
                                }}
                              >
                                <span className="pr-2">
                                  <FontAwesomeIcon
                                    className="text-[#797979]"
                                    icon={item.icon}
                                  />
                                </span>
                                <span className="text-black">{item.name}</span>
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {selectedItems.length > 0 ?
                <img
                src={appTheme==="light"?DeleteIcon:LightDelete}
                alt="deleteIcon"
                className="px-3"
                onClick={() => deleteCard()}
              />
              :
              <img
              src={appTheme==="light"?LightDelete:DeleteIcon}
              alt="deleteIcon"
              className="px-3"
              // onClick={() => deleteCard()}
            />
              }
                  <Transition appear show={isOpenss} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeCardModal}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                      </Transition.Child>

                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                Delete
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Are you sure you want to delete?
                                </p>
                              </div>

                              <div className="mt-4">
                                <button
                                  style={{
                                    background: "#FF6600",
                                    width: "80px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "50px",
                                  }}
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={() => handleDeleteCard()}
                                >
                                  Yes
                                </button>
                                <button
                                  style={{
                                    background: "#000",
                                    width: "80px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "50px",
                                  }}
                                  type="button"
                                  className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={closeCardModal}
                                >
                                  No
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                  {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
                  <img src={Arrow} alt="arrow" className="px-3" />
                </div>
                <div className="flex">
                  <div className="filterHolder flex items-center justify-end">
                    {/* <div className="filter display-content"> 
                            <a className="viewGrid" href={getUrl('list')}>                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                            </a>
                            <a className="viewGrid" href={getUrl('grid')}>                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a>
                        </div> */}

                    <div>
                      <Menu
                        as="div"
                        className="sortmbl-content relative flex-shrink-0"
                      >
                        <div>
                          <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                            <a
                              href="#"
                              className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                            >
                              <img src={SortMbl} alt="SortMbl" />
                            </a>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {filterDropDown.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    onClick={() => {
                                      handleFilterText(item.name);
                                    }}
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Single Card cardsgap-content flex flex-wrap  md:masonry-2-col lg:masonry-4-col mt-5*/}
          <div className="mansorydesktop-section">
            <Masonry
              breakpoints={breakpoints}
              columns={{ mobile: 1, tablet: 3, desktop: 4 }}
              gap={{ mobile: 2, tablet: 1, desktop: 15 }}
              style={{ display: "flex" }}
              // style={{paddingBottom:"10px"}}
            >
              {cardList?.map((card) => {
                // console.log(card);
                const sharedWithObjects = card?.shared
    ? card.shared
      .filter(shares => shares.sharedWith != null)
      .map(shares => shares.sharedWith)
    : [];
                return (
                  <SingleCard
                    key={card._id}
                    PermissionState={PermissionState}
                    handleSharePop={handleCardSharePop}
                    MainbucketId={MainbucketId}
                    MainbucketName={MainbucketName}
                    SharedToken={SharedToken}
                    sharedBy={[]}
                    sharedUsers={sharedWithObjects}
                    shared={card?.shared}
                    starred={card.starred}
                    buckets={buckets}
                    cardUpdate={cardUpdate}
                    cardId={card._id}
                    cardType={card.cardType}
                    sharedLength={card.shared.length}
                    title={card.title}
                    content={card.content}
                    note={card.note}
                    tags={card.tags}
                    postDetail={card.postDetail ? card.postDetail : ""}
                    updated={card.updatedAt}
                    handleCheckboxChange={handleCheckboxChange}
                    bucketUpdate={bucketUpdate}
                  />
                );
              })}
            </Masonry>
          </div>

          {isLoadings && (
            <Masonry
              breakpoints={breakpoints}
              columns={{ mobile: 2, tablet: 3, desktop: 4 }}
              gap={{ mobile: 20, tablet: 15, desktop: 15 }}
            >
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
            </Masonry>
          )}
        </div>
      )}
       {CardSharePopup && (
                            <div
                              className="filesuploadpopup relative !z-[999]"
                              style={{ height: "100%" }}
                              onClick={closeCardSharePop}
                            >
                              <div
                                className="subinviteupload-section"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="teaminvitesection flex">
                                  <div className="teammembers-content">
                                    Share
                                  </div>
                                  <div
                                    className="dismissSubfiles-content"
                                    onClick={closeCardSharePop}
                                  >
                                    <img
                                      src={BlackDismiss}
                                      alt="dismissButton"
                                    />
                                  </div>
                                </div>
                                <ShareCard
                                  shareBucketId={shareCardId}
                                  closeSharePop={closeCardSharePop}
                                  cardsRefresh={cardUpdate}
                                />
                              </div>
                            </div>
                          )}
      {popup === true ? (
        <CreateBucket
          bucketUpdate={bucketUpdate}
          cardUpdate={cardUpdate}
          changeTxt={changeTxt}
          bucketOperation={bucketOperation}
          bucketNameText={bucketNameText}
          bucketUpdateId={bucketUpdateId}
          parent={bucketId.id}
          setPopup={setPopup}
        />
      ) : null}
      {moveModal?<MoveModal openMove={openMove} closeModal={closeMove} selectedItems={selectedItems} cardUpdate={cardUpdate} buckets={buckets}/>:null}
      <Transition appear show={openCopyLink} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyLinkModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy and Paste this URL
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="URL"
                              value={publicUrl}
                              className="publicUrlInput"
                              // onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeCopyLinkModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleCopyText(publicUrl)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <ScrollToTop smooth color="#fff" component={<MySVG />} />
      <ToastContainer />
    </>
  );
}

export default BucketInner;
