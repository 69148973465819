/*global chrome*/
import { React, Fragment, useState,useRef } from "react";
import { useEffect } from "react"; 
import { Menu, Popover, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
// fontAwesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion,faBell, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// image import
import logo from "../assets/icons/logo.svg";
import darklogo from "../assets/icons/logo-dark-theme.svg";
import darkMode from "../assets/icons/Dark theme.svg";
import folderIcon from "../assets/icons/folderIcon.svg";
import helpIcon from "../assets/icons/help.svg";
import menuIcon from "../assets/icons/menu-icon.svg"
import magnifier from "../assets/icons/magnifyGlass.svg"
import notification from "../assets/icons/Notification.svg" 
import profileImage from "../assets/icons/profile-image.png"
import CheckboxIcon from "../assets/icons/checkbox.svg";
import {ReactComponent as MagnifyGlass} from "../assets/icons/magnifyGlass.svg";
import './topBar.scss';
import Switcher from "../components/Switcher";
import NavLeft from './NavLeft';
import DismissButton from "../assets/icons/dismissButton.svg";
import Avatar from 'react-avatar';
import { orange } from "@material-ui/core/colors";
  const user = {
    name: 'Chelsea Hagon',
    email: 'chelsea.hagon@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  
  const userNavigation = [
    { name: 'Download chrome extension', href: 'https://chromewebstore.google.com/detail/zimzee/joeibnoddmkbggaacjmfnefdmpdgpkmb', target: "_blank"},
    { name: 'My Profile', href: '/app/myProfile', target: "_self"},
    { name: 'My Subscription', href: '/app/mySubscription', target: "_self" },
    { name: 'Manage team', href: '/app/manageTeam', target: "_self" },
    { name: 'Settings', href: '/app/settings', target: "_self" },
    { name: 'Sign out', href: '#', target: "_self" },
  ]
 
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

 
        
function TopBar({ToggleBtn}){
  const navigate = useNavigate();
  const [filepopup,setFilePopup] = useState(false);
  const [selectedProfile,setSelectedProfile]=useState('')
  const [fullName,setFullName]=useState('')
  const[firstName,setFirstName]=useState('')
  const[lastName,setLastName]=useState('')
  const [manageTeam, SetManageTeam] = useState(false);
  const [manageTopTeam, SetManageTopTeam] = useState(false);
  const handleFilePopup = () =>{
    setFilePopup(true);
  }
  useEffect(() => {
    if(localStorage.getItem('userData')){
      let userData =JSON.parse(localStorage.getItem('userData'));
      SetManageTeam(userData.isTeamMember && !userData.isActiveSubscription ? userData.isTeamMember : false);
      const fetchData = async () => {
        const token = localStorage.getItem('token');
   
        try {
          const response = await axios.get(
            'https://api.zimzee.com/api/users/me',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response, 'Me response');
          setFullName(response?.data?.data?.fullName)
          setSelectedProfile(response?.data?.data?.imageUrl)
          setFirstName(response?.data?.data?.firstName)
          setLastName(response?.data?.data?.lastName)
         if(!response?.data?.data?.status){
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
            localStorage.removeItem('settingsToken')
            localStorage.removeItem('cardFilter');
            localStorage.removeItem('bucketFilter');
            localStorage.removeItem('bucketView');
            localStorage.removeItem('sharecardFilter');
            localStorage.removeItem('subscriptionpriceId');
            localStorage.removeItem('subscriptionType');
            localStorage.removeItem('renewPlan');
            localStorage.removeItem('renewPrice');
            localStorage.removeItem('renewPriceId');
            localStorage.removeItem('planPrice');
            localStorage.removeItem('subscriptionplanId');
            localStorage.removeItem('sharedClickCardId');
            localStorage.removeItem('sharedCardId');
            localStorage.removeItem('cardFilter');
            localStorage.removeItem('browserId');
            sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
            navigate('/');
         }
        } catch (error) {
          console.error(error);
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
            localStorage.removeItem('settingsToken')
            localStorage.removeItem('cardFilter');
            localStorage.removeItem('bucketFilter');
            localStorage.removeItem('bucketView');
            localStorage.removeItem('sharecardFilter');
            localStorage.removeItem('subscriptionpriceId');
            localStorage.removeItem('subscriptionType');
            localStorage.removeItem('renewPlan');
            localStorage.removeItem('renewPrice');
            localStorage.removeItem('renewPriceId');
            localStorage.removeItem('planPrice');
            localStorage.removeItem('subscriptionplanId');
            localStorage.removeItem('sharedClickCardId');
            localStorage.removeItem('sharedCardId');
            localStorage.removeItem('cardFilter');
            localStorage.removeItem('browserId');
            sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
            navigate('/');
        }
      };
  
      fetchData(); // Call the async function immediately
    }
    // The empty dependency array means this effect runs once after initial render.
  }, [manageTopTeam]);

  function closeFilePopup(){
    setFilePopup(false);
  }
  const handleDashboard=()=>{
    navigate(`/app/dashboard`)
  }


  const sendTokenToChromeExtension = ({ extensionId, removejwt }) => {
    try {
        if(chrome && chrome?.runtime){
            chrome.runtime.sendMessage(extensionId, { removejwt }, response => {
                // if (!response.success) {
                //   console.log('error sending message', response);
                //   return response;
                // }
                // console.log('Sucesss ::: ', response.message)
            
            });
        }
    } catch (error) {
        console.log(error)
    }
}
const handleTopbarMenu = async () => {
  SetManageTopTeam(!manageTopTeam);
}
  const handleLogout = async(text) => {
     if(text == 'Sign out'){
      console.log("sign out");
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('settingsToken')
      localStorage.removeItem('cardFilter');
      localStorage.removeItem('bucketFilter');
      localStorage.removeItem('bucketView');
      localStorage.removeItem('sharecardFilter');
      localStorage.removeItem('subscriptionpriceId');
      localStorage.removeItem('subscriptionType');
      localStorage.removeItem('renewPlan');
      localStorage.removeItem('renewPrice');
      localStorage.removeItem('renewPriceId');
      localStorage.removeItem('planPrice');
      localStorage.removeItem('subscriptionplanId');
      localStorage.removeItem('sharedClickCardId');
      localStorage.removeItem('sharedCardId');
      localStorage.removeItem('cardFilter');
      localStorage.removeItem('browserId');
      sendTokenToChromeExtension({ extensionId: 'joeibnoddmkbggaacjmfnefdmpdgpkmb', removejwt: true });
      navigate('/');
     }
  }


  if(!localStorage.getItem('userData')){
    navigate('/');
  }

    return(
        <div>
          <div className="topbardesktop-section">
            <div style={{boxShadow:'0 5px 17px rgba(0,0,0,0.07)'}} className="topBar relative zIndex-1 dark:bg-[#051534]">
              <Popover
                as="header"
                className={({ open }) =>
                  classNames(
                    open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                    'bg-white lg:static lg:overflow-y-visible'
                  )
                }
              >
                  {({ open }) => (
                    <>
                      <div className="mx-auto  w-full px-4 sm:px-6 pd-24 dark:bg-[#051534]">
                        <div className="relative flex justify-between">
                          <div className="flex">
                            <div className="flex flex-shrink-0 items-center ">
                              <img className="menu" src={menuIcon} onClick={ToggleBtn} alt="menu-icon" />
                             
                              <a href={`javascript:void(0);`} onClick={handleDashboard}>
                                <img
                                  className="light-theme-logo block w-full pl-5"
                                  src={logo}
                                  alt="Zimzee-Logo"
                                />
                              </a>
          
                              <a href={`javascript:void(0);`} onClick={handleDashboard}>
                                <img
                                  className="dark-theme-logo block w-full pl-5"
                                  src={darklogo}
                                  alt="Zimzee-Logo"
                                />
                              </a>
                            </div>
                          </div>
                          {/* <div className="flex items-center md:px-8 lg:px-0 w-8/12">
                            <div className="searchwhole-content flex item-center ">
                              <div className="w-full">
                                <label  htmlFor="search" className="dark:border-white-700 sr-only">
                                  Search
                                </label>
                                <div className="relative">                 
                                  <input
                                    id="search"
                                    name="search"
                                    className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                                    placeholder="Search"
                                    type="search"
                                  />
                                  <div className="dark:bg-[#202F4F] pointer-events-none rounded-3xl absolute inset-y-0 right-0 flex items-center pr-2 mr-[10px] bg-[#F5F5F5]">
                                   <img className="magnifier" src={magnifier} alt="magnifying-glass" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            
<div class="checkbox-wrapper-23">
  <input type="checkbox" id="check-23"/>
  <label for="check-23" > 
  <svg viewBox="0,0,50,50">
      <path d="M5 30 L 20 45 L 45 5"></path>
    </svg>
  </label> 
  <span className="dark:text-[#acacac]">Search in Kindle Highlights</span>
</div>
                          </div> */}
                      
                          <div className="theme-content flex justify-between items-center lg:w-[280px] lg:pl-5">
                            {/* Profile dropdown */}
                            {/* <img className="topbar-icon"  src={folderIcon} alt="folderIcon"/> */}
                            {/* <img  src={darkMode} alt="darkMode"/> */}
                            <Switcher />
                            {/* <img className="topbar-icon" src={helpIcon} alt="helpIcon"/> */}
                            <img className="topbar-icon" src={notification} alt="notification" />


                            {/* <FontAwesomeIcon style={{fontSize:'19px'}} icon={faQuestion} /> */}
                            <Menu as="div" className="relative flex-shrink-0">
                              <div onClick={handleTopbarMenu}>
                                <Menu.Button className="flex focus:outline-none">
                                      <a
                                        href="#"
                                        className="flex-shrink-0  p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                                      >
                                      <span className="sr-only">View notifications</span>
                                      {selectedProfile ? (
        <div>
          <img className="profiledetailimg-content" style={{width:"33px", height: "33px", borderRadius: "100px",objectFit:"cover", border: "1px solod #ffffff"}} src={selectedProfile} alt="profileImg" />
        </div>
      ) : (
        <div>
          {/* Display a default image or a placeholder */}
          <Avatar name={`${firstName} ${lastName}`} size="34"round={true} style={{fontSize: "9px", letterSpacing: "1px"}}/>
          {/* <img className="profiledetailimg-content" src={ProfileImg} alt="profileImg" /> */}
        </div>
      )}

                                  </a>
                                </Menu.Button>
                              </div>
                              
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {userNavigation.map((item) => (
                                    item.name == 'Manage team' || item.name == 'My Subscription' ?
                                    !manageTeam ?
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-2 text-sm text-gray-700'
                                          )}
                                          target={item.target}
                                          onClick={()=>{handleLogout(item.name)}}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                    : ""
                                    :
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-2 text-sm text-gray-700'
                                          )}
                                          target={item.target}
                                          onClick={()=>{handleLogout(item.name)}}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>

                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  </Popover>
            </div>
          </div>
          <div className="topbarmbl-section">
          <div style={{boxShadow:'0 5px 17px rgba(0,0,0,0.07)'}} className="topBar relative zIndex-1 dark:bg-[#051534]">
              <Popover
                as="header"
                className={({ open }) =>
                  classNames(
                    open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                    'bg-white lg:static lg:overflow-y-visible'
                  )
                }
              > 
                  {({ open }) => (
                    <>
                      <div className="mx-auto  w-full px-4 sm:px-6 pd-24 dark:bg-[#051534]">
                        <div className="relative flex justify-between">
                          <div className="flex">
                            <div className="flex flex-shrink-0 items-center ">
                              <img className="menu" src={menuIcon} alt="menu-icon" onClick={handleFilePopup} /> 
                              {filepopup &&
                                <div className="filescreationpopup relative !z-[999]" style={{height:"100%"}}>
                                   <div className="subfilestopbar-section" onClick={closeFilePopup}>
                                    <img src={DismissButton} className="topbardismiss-mblcontent" alt="dismissButton" onClick={closeFilePopup}/>
                                    <NavLeft />
                                  </div>
                                </div>
                              }
                              <a href="#">
                                <img
                                  className="light-theme-logo block w-full pl-5"
                                  src={logo}
                                  alt="Zimzee-Logo"
                                />
                              </a>
          
                              <a href="#">
                                <img
                                  className="dark-theme-logo block w-full pl-5"
                                  src={darklogo}
                                  alt="Zimzee-Logo"
                                />
                              </a>
                            </div>
                          </div>
                          {/* <div className="flex items-center md:px-8 lg:px-0 w-8/12">
                            <div className="searchwhole-content flex item-center ">
                              <div className="w-full">
                                <label  htmlFor="search" className="dark:border-white-700 sr-only">
                                  Search
                                </label>
                                <div className="relative">                 
                                  <input
                                    id="search"
                                    name="search"
                                    className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                                    placeholder="Search"
                                    type="search"
                                  />
                                  <div className="dark:bg-[#202F4F] pointer-events-none rounded-3xl absolute inset-y-0 right-0 flex items-center pr-2 mr-[10px] bg-[#F5F5F5]">
                                   
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* <div className="kindleSearch flex items-center">
                              <input type="checkBox" />
                              <span className="px-3">Search in Kindle Highlights test</span>
                            </div> */}
                            {/* <div className="searchBar">
      <input id="searchInput" className="form-control" aria-describedby="searchInput" type="checkbox"  />
      <label for="searchLabel">Search in Kindle Highlights</label>
</div> */}
{/* <div class="checkbox-wrapper-23">
  <input type="checkbox" id="check-23"/>
  <label for="check-23" > 
  <svg viewBox="0,0,50,50">
      <path d="M5 30 L 20 45 L 45 5"></path>
    </svg>
  </label> 
  <span className="dark:text-[#acacac]">Search in Kindle Highlights</span>
</div> */}
                          {/* </div> */}
                      
                          <div className="theme-content flex justify-between items-center lg:w-[280px] lg:pl-5">
                            {/* Profile dropdown */}
                            {/* <img className="topbar-icon"  src={folderIcon} alt="folderIcon"/> */}
                            {/* <img  src={darkMode} alt="darkMode"/> */}
                            <Switcher />
                            <img className="magnifier" src={magnifier} alt="magnifying-glass" />
                            <img className="topbar-icon" src={notification} alt="notification" />


                            {/* <FontAwesomeIcon style={{fontSize:'19px'}} icon={faQuestion} /> */}
                            <Menu as="div" className="relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex focus:outline-none">
                                      <a
                                        href="#"
                                        className="flex-shrink-0  p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                                      >
                                      {/* <span className="sr-only">View notifications</span>
                                      <img style={{width: "24px"}} src={profileImage} /> */}
                                      <span className="sr-only">View notifications</span>
                                      {selectedProfile ? (
        <div>
          <img className="profiledetailimg-content" style={{width:"33px", height: "33px", borderRadius: "100px",objectFit:"cover", border: "1px solod #ffffff"}} src={selectedProfile} alt="profileImg" />
        </div>
      ) : (
        <div>
          {/* Display a default image or a placeholder */}
          <Avatar name={`${firstName} ${lastName}`} size="34"round={true} style={{fontSize: "9px", letterSpacing: "1px"}}/>
          {/* <img className="profiledetailimg-content" src={ProfileImg} alt="profileImg" /> */}
        </div>
      )}
                                  </a>
                                </Menu.Button>
                              </div>
                              
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                          )}
                                          target={item.target}
                                          onClick={()=>{handleLogout(item.name)}}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>

                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  </Popover>
            </div>
          </div>
        </div>
          )
        }


export default TopBar;