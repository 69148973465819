import { React, Fragment, useState,useRef } from "react";
import { useEffect } from "react"; 
import axios from "axios";
import { toastify } from "../../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEllipsisVertical,
  faSearch,
  faClose
} from "@fortawesome/free-solid-svg-icons";
const MoveModal=({openMove,closeModal,selectedItems,cardUpdate,buckets})=>{
    const [searchBucket, setSearchBucket] = useState("");
    const [bucketId, setBucketId] = useState("");
    const [isBucketOpen, setIsBucketOpen] = useState(false);
    const [selectedBucket, setSelectedBucket] = useState("");    
    // const [buckets, setBuckets] = useState([]);
    const closeMoveModal=()=>{
        closeModal()
    setSearchBucket("");
    }
    // useEffect(() => {
    //     // Function to fetch data from the API and update the state
    //     const token = localStorage.getItem("token");
    //     const fetchBuckets = async () => {
    //       try {
    //         const response = await axios.get(
    //           `${process.env.REACT_APP_API}buckets/user-buckets`,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${token}`, // Include the token in the request header
    //             },
    //           }
    //         ); // Replace this with the actual API endpoint
    //         setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       }
    //     };
    //     if (token) {
    //       fetchBuckets();
    //     }
    //   }, []);
    const handleUpdateCardMenu = async (Id) => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.post(
            "https://api.zimzee.com/api/cards/bulk-cards-move",
            {
                cardIds: selectedItems,
              destinationBucketID: selectedBucket
              
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response);
          toastify("success", response.data.message);
          if (response) {
            closeMoveModal();
            cardUpdate()
          }
    
          setSelectedBucket("");
          // cardUpdate();
        } catch (error) {
          console.error(error);
          setSelectedBucket("");
          toastify("error", error.response.data.message);
        }
      };
    const handleBucketChange = (bucket) => {
        const Selectbucket = bucket._id;
        setSelectedBucket(Selectbucket);
        const newBucket = bucket.name;
        setSearchBucket(newBucket);
        setIsBucketOpen(false);
      };
      const handleBucketInputChange = (event) => {
        const inputText = event.target.value;
        setSearchBucket(inputText.toLowerCase());
      };
      const handleBucketClear = () => {
        setSearchBucket("");
      };
      const filteredBuckets = buckets?.filter((bucket) => {
        const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
        const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
          subBucket.name.toLowerCase().includes(searchBucket)
        );
        return bucketNameMatches || subBucketMatches;
      });
      const handleSubBucketSelectChange = (subbucket) => {
        const newBucket = subbucket._id;
        setSelectedBucket(newBucket);
        setSearchBucket(subbucket.name);
        setIsBucketOpen(false);
        // setHoveredOption(hoveredOption);
        // handleTagsChange([...tags, newTag]);
        // handleBlur()
      };
    return (
        <>
<Transition appear show={openMove} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeMoveModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Move To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="flex fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon test">
                              {searchBucket ? (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faClose}
                                  onClick={handleBucketClear}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faSearch}
                                //   onClick={handleBucketOpen}
                                />
                              )}
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                      {option.subBuckets &&
                                      option.subBuckets.length > 0 ? (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            position: "absolute",
                                            right: "17px",
                                            top: "14px",
                                          }}
                                        />
                                      ) : null}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeMoveModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={handleUpdateCardMenu}
                            >
                              <span className="savebutton-context">Move</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
</>
  );
}
export default MoveModal;