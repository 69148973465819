import { React, Fragment, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBell, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";
import Profile1 from "../../assets/icons/profile1.png";
import BookmarksShared from "../../assets/icons/bookmarksShared.svg";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import CardImage1 from "../../assets/images/cardImage1.png";
import linkIcon from "../../assets/icons/linksicon.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import BookmarksIcon from "../../assets/images/bookmarksIcon.svg";
import BookmarksImg1 from "../../assets/images/bookmarksimg1.png";
import BookmarksImg2 from "../../assets/images/bookmarksimg2.png";
import BookmarksImg3 from "../../assets/images/bookmarksimg3.png";
import BookmarksImg4 from "../../assets/images/bookmarksimg4.png";
import BookmarksImg5 from "../../assets/images/bookmarksimg5.png";
import BookmarksImg6 from "../../assets/images/bookmarksimg6.png";
import resizeIcon from "../../assets/icons/resizeIcon.svg";
import logo from "../../assets/images/facebooklogo.png";
import parse from "html-react-parser";
import moment from "moment";
import Avatar from "react-avatar";
import Union from "../../assets/icons/Union-19.svg";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import BookmarkPreview from "../../assets/icons/bookmarksPreviews.svg";
import FbIcon from "../../assets/images/fblogo.png";
import twitterlogo from "../../assets/images/twitternewlogo.svg";
import defaultUser from "../../assets/images/default-user.png";

import ZimzeeIcon from "../../assets/icons/logoSymbol.svg";
import youtubeIcon from "../../assets/images/youtube.svg";
import tiktokIcon from "../../assets/images/download-tiktok.png";
import axios from "axios";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Tags from "../../shades/cards/includes/Tags";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function SingleBookmark({
  title,
  content,
  note,
  tags,
  updated,
  sharedLength,
  cardId,
  starred,
  cardType,
  cardsRefresh,
  handleCheckboxChange,
  cardUpdate,
  buckets,
  postDetail,
  bookmarkCards,
  MainMenuText,
  handleSharePop,
  SharedToken,
  PermissionState,
  sharedUsers,
  sharedBy,
}) {
  console.log(sharedUsers, "sharedUsers single");
  const [isActive, setIsActive] = useState(starred);
  const [isActiveVideo, setIsActiveVideo] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  // const [SelectedCard,setSelectedCard]=useState([])
  let [isOpenss, setIsOpens] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");
  const [selectedBucketId, setselectedBucketId] = useState("");
  const [searchBucket, setSearchBucket] = useState("");
  const [bucketId, setBucketId] = useState("");
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [downloadImg, setdownloadImg] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(bookmarkCards, "data in inner bookmark");
    // console.log(buckets,"buckets---------------------");
  }, [bookmarkCards]);
  // window.scrollTo(0, 0);
  // useEffect(()=>{
  //     let cardContent = document.querySelectorAll('.cardContent > div[dir="auto"]');
  //     // console.log(cardContent,"card");
  //     var valueContent;
  //     cardContent.forEach(function(value,i){
  //         valueContent = value.textContent;
  //         // console.log(valueContent);
  //     });
  //     // console.log(valueContent);
  // },[])
  let StyleDiv = "";
  if (content?.html) {
    const tempElementContent = document.createElement("div");
    tempElementContent.innerHTML = content.html;
    let cardContent = tempElementContent.querySelector(
      "div.x6s0dn4.x1jx94hy.x78zum5.xdt5ytf.x6ikm8r.x10wlt62.x1n2onr6.xh8yej3"
    );
  }
  // const handleClick = () => {
  //   handleCardClick(cardId); // Pass the cardId to the parent component's function
  // };
  const closeDuplicateModal = () => {
    setOpenDuplicate(false);
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const createMarkup = (htmlString) => {
    const replacedHtml = htmlString
      .replace(/<\/p>/g, "<br>")
      .replace(/<p>/g, "");
    return { __html: replacedHtml };
  };
  const handleCardLink = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://api.zimzee.com/api/cards/public-url",
        {
          itemId: cardId,
          isPublic: true,
          itemType: "Card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        // Create a temporary input element to copy the link to clipboard
        const tempInput = document.createElement("input");
        tempInput.value = response?.data?.data;

        // Append the input element to the DOM
        document.body.appendChild(tempInput);

        // Select the input element's text and copy it to clipboard
        tempInput.select();
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can show a message to indicate that the link has been copied
        console.log(response.data.message);
        toastify("success", response.data.message);
      }
    } catch (error) {
      console.error(error?.response?.data?.message, "error msg");
      toastify("error", error?.response?.data?.message);
    }
  };
  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };
  const handleNotesMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleNotesMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };
  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const handleBucketId = (e) => {
    setBucketId(e.target.value);
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleCardMenu = (name) => {
    switch (name) {
      case "Delete":
        setIsOpens(true);
        break;
      case "Edit Name":
        setSearchBucket(title);
        setOpenEdit(true);
        break;
      case "Move To":
        setOpenMove(true);
        setOptionSelect("MoveTo");
        break;
      case "Copy To":
        setOpenCopy(true);
        setOptionSelect("CopyTo");
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  };

  const handleSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setSelectedBucket(newBucket);
    setSearchBucket(subbucket.name);
    setIsBucketOpen(false);
    // setHoveredOption(hoveredOption);
    // handleTagsChange([...tags, newTag]);
    // handleBlur()
  };
  const updateCard = async (cardId, star) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        { cardId: cardId, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleCheckboxChanges = (event) => {
    handleCheckboxChange(event);
  };
  const handleBucketOpen = () => {
    setIsBucketOpen(!isBucketOpen);
  };
  const handleBucketClear = () => {
    setSearchBucket("");
  };
  // const filteredBuckets = buckets?.filter((bucket) =>
  //   bucket.name.toLowerCase().includes(searchBucket.toLowerCase())
  // );
  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });
  const handleStarClick = async (cardId) => {
    setIsActive(!isActive);
    await updateCard(cardId, !isActive);
  };
  const handleBucketInputChange = (event) => {
    setSearchBucket(event.target.value);
  };
  const handleCardShare = () => {
    console.log("card share click");
    handleSharePop(cardId);
  };
  const handlePlayVideo = async (event, cardId) => {
    event.preventDefault();
    if (isPlaying && cardId == isActiveVideo) {
      document
        .querySelector(".video-content-inner-" + cardId + " video")
        .pause();
    } else {
      setIsPlaying(false);

      document
        .querySelector(".video-content-inner-" + cardId + " video")
        .play();
    }
    setIsActiveVideo(cardId);
    setIsPlaying(!isPlaying);
    // event.stopPropagation();

    // handlePlayVideo(cardId);
  };

  const bucketNav = [
    { icon: faTrash, name: "Delete", href: "javascript:void(0)" },
    { icon: faPencil, name: "Edit Name", href: "javascript:void(0)" },
    { icon: faNoteSticky, name: "Move To", href: "javascript:void(0)" },
    { icon: faNoteSticky, name: "Copy To", href: "javascript:void(0)" },
  ];
  const handleAction = (item) => {
    if (item.name === "Delete") {
      setIsOpens(true);
      setOptionSelect("");
    }
    if (item.name === "Duplicate") {
      setOpenDuplicate(true);
      setOptionSelect("Duplicate");
      setBucketId("");
    }
    if (item.name === "Move To") {
      setOpenMove(true);
      setOptionSelect("MoveTo");
    }
    if (item.name === "Copy To") {
      setOpenCopy(true);
      setOptionSelect("CopyTo");
    }
    if (item.name === "Edit Name") {
      navigate(`/app/CardEdit/${cardId}`);
    }
  };
  const closeMoveModal = () => {
    setIsBucketOpen(false);
    setOpenMove(false);
  };
  const closeCopyModal = () => {
    setOpenCopy(false);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function closeCardModal() {
    setIsOpens(false);
  }
  const handleDeleteCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: Id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardsRefresh();
      closeCardModal();
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };
  const handleUpdateCardMenu = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        {
          cardId: Id,
          title: searchBucket,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        setOpenEdit(false);
        cardsRefresh();
      }

      // cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleUpdateCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        {
          optionType: optionSelect,
          destinationBucketID: selectedBucket,
          cardId: Id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        // cardUpdate();
        setOpenCopy(false);
        setOpenMove(false);
        cardsRefresh();
      }

      setSelectedBucket("");
      // cardUpdate();
    } catch (error) {
      console.error(error);
      setSelectedBucket("");
      toastify("error", error.response.data.message);
    }
  };
  let videoIframe = "";
  if (
    content?.imageUrl.length > 0 &&
    content?.imageUrl[0].urlType == "Video" &&
    content?.html &&
    content?.imageUrl[0].url
  ) {
    videoIframe = content?.html;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = videoIframe;

    // const anchorTag = document.createElement('a');
    // const divElementWithDirAuto = tempElement.querySelector('div[dir="auto"]');
    // if (divElementWithDirAuto) {
    //     // Append the div element as a child of the anchor tag
    //     const newChild = anchorTag.appendChild(divElementWithDirAuto);
    //     console.log(newChild);

    //     // Get the actual container where you want to inject the anchor tag
    //     // const container = document.getElementById('container');

    //     // Append the anchor tag to the container
    //     // container.appendChild(anchorTag);
    //     // divElementWithDirAuto.parentNode.replaceChild(newChild, divElementWithDirAuto);
    //   }
    // tempElement.find('iframe').attr('')
    const iframes = tempElement.querySelector("iframe");
    if (iframes) {
      const videoElement = document.createElement("video");
      videoElement.controls =
        isPlaying && cardId == isActiveVideo ? true : false;
      // videoElement.autoplay = true;

      const sourceElement = document.createElement("source");
      sourceElement.src = content?.imageUrl[0].url;
      sourceElement.type = "video/mp4";

      videoElement.appendChild(sourceElement);
      const playIcon = document.createElement("div");
      playIcon.className = "play-icon";
      playIcon.innerHTML = "&#9658;";

      // Wrap the video element with a container div
      const videoContainer = document.createElement("div");
      videoContainer.className = "video-container";
      videoContainer.appendChild(videoElement);
      // videoContainer.appendChild(playIcon);

      // Replace the iframe with the video element
      iframes.parentNode.replaceChild(videoContainer, iframes);
    }

    // Get the updated HTML content from the temporary container
    const updatedHtml = tempElement.innerHTML;
    // console.log(updatedHtml,"updatedHtml");
    videoIframe = updatedHtml;
  }
  return (
    <div>
      <ToastContainer />
      <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
        <a
          href={
            SharedToken && PermissionState
              ? `/app/card-detail/${cardId}?card=bookmark&shared=true&permission=${PermissionState}`
              : `/app/card-detail/${cardId}?card=bookmark`
          }
        >
          <div className="cardContent w-full">
            <div
              className={`inputCheck ${
                SharedToken ? "pointer-events-none" : ""
              }`}
            >
              <input
                type="checkbox"
                value={cardId}
                onChange={handleCheckboxChanges}
              />
            </div>

            <img
              className="imagesmall-content"
              src={
                content?.imageUrl[0]?.url
                  ? content?.imageUrl[0]?.url
                  : BookmarkPreview
              }
              alt="projectImg1"
            />
          </div>
          
          <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                        {title}{" "}
                        </p>
                      </span>
                      <div className="brandspace-content">
            <span className="brandtext-content">
              <a
                href={
                  SharedToken && PermissionState
                    ? `/app/card-detail/${cardId}?card=bookmark&shared=true&permission=${PermissionState}`
                    : `/app/card-detail/${cardId}?card=bookmark`
                }
              >
                {title}{" "}

              </a>
            </span> 
          </div>
                    </div>
          <div className="flex">
            <div>
              <div className="fashioncards-content createalign-content">
                <img className="mr-2" src={BookmarksIcon} alt="bookmarksIcon" />
                {moment(updated).format("MMM D, YYYY @ hh:mm A")}
              </div>
              <div
                className="uploadedcards-content"
                // style={{ overflow: "hidden", whiteSpace: "nowrap" }}
              >
                <a
                  href={content?.sourceUrl?.includes("youtube") 
                    ? content.sourceUrl 
                    : content?.sourceUrl?.split("?")[0]}
                  target="_blank"
                  style={{ display: "flex", width: "280px" }}
                >
                  <img
                    className="linkcards-icon mr-3"
                    src={BookmarksShared}
                    alt="link-icon"
                    style={{ width: "15px" }}
                  />
                  {/* <div>
                    {content?.sourceUrl?.split("?")[0]}{" "}
                  </div> */}

                  <div className="my-auto name-whatishared">
                    <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {content?.sourceUrl?.includes("youtube") 
                          ? content.sourceUrl 
                          : content?.sourceUrl?.split("?")[0]}{" "}
                        </p>
                      </span>
                      <h6 className="bucket-title">
                        {content?.sourceUrl?.includes("youtube") 
                          ? content.sourceUrl 
                          : content?.sourceUrl?.split("?")[0]}{" "}
                      </h6>
                    </div>

                    {/* <p className="bucket-name">Fashion Bucket</p> */}
                  </div>
                  {/* <div className="tooltip">
                    <span className="tooltip-text fileshrename">
                      <p className="designfile-textcontent">{content?.sourceUrl?.split("?")[0]}{" "}</p>
                    </span>
                    <h3 className="design-textcontent">{content?.sourceUrl?.split("?")[0]}{" "}</h3>
                  </div> */}
                </a>
              </div>
            </div>
            {/* <div className="profile-displaycontent">
       <div className="profile-imgcontent">
       <img src={Profile1} alt="profile1" />
       <img src={Profile2} alt="profile2" /> 
       <img src={Profile3} alt="profile3" /><br/>
       </div>
       <p className="sharesright-content">0+ Shares</p>
   </div> */}
          </div>
          <div className="flex items-center flex-wrap">
            {/* <div
              className="cardTags cardTags-content flex flex-wrap py-1"
              style={{ width: "80%" }}
            >
              {tags?.length > 0 ? (
                <>
                  {tags.slice(0, 3).map((item) => (
                    <span
                      key={item.name}
                      className={`tagsnamefile-textcontent ${
                        tags?.length > 1 ? "tagsnamefile-truncated" : ""
                      } py-1 px-2 mr-[6px] my-1 rounded-2xl`}
                    >
                      {item.name}
                    </span>
                  ))}
                  {tags?.length > 3 && (
                    <span className="tagsnamefile-textcontent tagsnamefile-truncated py-1 px-1 mr-[6px] my-1 rounded-2xl">
                      +{tags?.length - 3} {tags?.length - 3 === 1}
                    </span>
                  )}
                </>
              ) : (
                <span className="tagsnamefile-textcontent py-1 px-2 mr-[6px] my-1 rounded-2xl">
                  No Tags Assigned
                </span>
              )}
            </div> */}
            <Tags
              listTags={tags}
              isShare={sharedUsers?.length > 0 ? false : true}
            />
            {sharedUsers?.length > 0 && (
              <div className="profile-displaycontent">
                <div className="profile-imgcontent">
                  {SharedToken && sharedUsers ? (
                    <div className="tooltip">
                      <span className="tooltip-text">
                        <p>Share By : &nbsp;{sharedBy}</p>
                        <p>
                          {" "}
                          Share with :&nbsp;
                          {sharedUsers?.map((user, index) => (
                            <span
                              key={index}
                              style={{ display: "inline-flex" }}
                            >
                              {user?.fullName}{" "}
                              {index < sharedUsers.length - 1 ? "," : ""}
                            </span>
                          ))}{" "}
                        </p>
                      </span>
                      {sharedUsers?.slice(0, 3)?.map((user) => (
                        <>
                          {user?.imageUrl ? (
                            <img src={user?.imageUrl} alt="profile1" />
                          ) : (
                            <Avatar
                              name={`${user.fullName}`}
                              size="25"
                              round={true}
                              className="profile-avatar"
                            />
                          )}
                        </>
                      ))}
                    </div>
                  ) : (
                    sharedUsers?.slice(0, 3)?.map((user) => (
                      <div className="tooltip">
                        <span className="tooltip-text">
                          {/* <p>Share By &nbsp;{sharedBy}</p> */}
                          <p>
                            {" "}
                            Share with : &nbsp;
                            {sharedUsers?.map((user, index) => (
                              <span
                                key={index}
                                style={{ display: "inline-flex" }}
                              >
                                {user?.fullName}{" "}
                                {index < sharedUsers.length - 1 ? "," : ""}
                              </span>
                            ))}{" "}
                          </p>
                        </span>
                        <>
                          {user?.imageUrl ? (
                            <img src={user?.imageUrl} alt="profile1" />
                          ) : (
                            <Avatar
                              name={`${user.fullName}`}
                              size="25"
                              round={true}
                              className="profile-avatar"
                            />
                          )}
                        </>
                      </div>
                    ))
                  )}

                  {sharedUsers.length > 3 && (
                    <div className="plus-share"> +</div>
                  )}
                </div>
                <p className="share-content dark:text-[#d3d3d3]">
                  {sharedUsers?.length > 0
                    ? sharedUsers?.length + " Share(s)"
                    : null}
                </p>
              </div>
            )}
          </div>
        </a>
        <div
          className={`flex cardsicon-content ${
            SharedToken ? "pointer-events-none" : ""
          }
`}
        >
          <div className="bookmark-tooltip-container">
            {note && /\S/.test(stripHtmlTags(note)) ? (
              <img
                className="tooltip-icon bookmarkiconsingle-content clipicon-content"
                src={clipboardNotes}
                alt="clipboardNotes"
                style={{ width: "20px", paddingTop: "8px" }}
                onMouseEnter={handleNotesMouseEnter}
                onMouseLeave={handleNotesMouseLeave}
              />
            ) : (
              <img
                className="tooltip-icon bookmarkiconsingle-content clipicon-content"
                src={ClipBoard}
                alt="clipboardNotes"
                style={{ width: "20px", paddingTop: "8px" }}
              />
            )}
            {isTooltipVisible && note && (
              <div className="bookmark-tooltip">
                <p dangerouslySetInnerHTML={createMarkup(note)} />
              </div>
            )}
          </div>
          <img
            className="bookmarkiconsingle-content"
            src={SubShare}
            alt="subShare"
            onClick={handleCardShare}
          />
          <button type="button" onClick={() => handleStarClick(cardId)}>
            {" "}
            {isActive ? (
              <img
                className="bookmarkiconsingle-content"
                src={Union}
                alt="subStars"
                style={{ width: "20px", marginRight: "60px" }}
              />
            ) : (
              <img
                className="bookmarkiconsingle-content"
                src={SubStar}
                alt="subStar"
              />
            )}
          </button>
          {/* <img className="subtags mr-7" src={SubTag} alt="subTag"/>    */}
          <img
            className="cardiconlink-imgcontent bookmarkiconsingle-content linkbookmarksingle-content bookmarkiconstar-content"
            src={linkIcon}
            alt="link-icon"
            onClick={handleCardLink}
            style={{ width: "18px" }}
          />
          <Menu as="div" className="relative">
            <Menu.Button className="relative inline-flex items-center text-sm font-semibold text-gray-900 bookmarksingledot-content">
              {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
              <img
                src={ThreeDots}
                alt="threeDots"
                className="threedots-content"
                style={{ paddingTop: "5px" }}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute text-left right-0 z-10  w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style={{ top: "-120px" }}
              >
                {bucketNav.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block py-2 px-4 text-sm text-gray-700"
                        )}
                        onClick={() => handleCardMenu(item.name)}
                      >
                        <span className="pr-2">
                          <FontAwesomeIcon
                            className="text-[#797979]"
                            icon={item.icon}
                          />
                        </span>
                        <span className="text-black">{item.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <img  src={ThreeDots} alt="threeDots"  /> */}
        </div>
      </div>
      <Transition appear show={openEdit} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeEditModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="test w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Name
                  </Dialog.Title>
                  <div
                    style={{ display: "block" }}
                    className="custom-list-dropdown"
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Edit Bookmark Name"
                        value={searchBucket}
                        onChange={handleBucketInputChange}
                      />
                    </div>
                    <div className="flex justify-end">
                      <div
                        className="cancelfilecard-content"
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenEdit(false)}
                      >
                        Cancel
                      </div>
                      <button
                        style={{
                          filter:
                            "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                        }}
                        className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                        onClick={() => handleUpdateCardMenu(cardId)}
                      >
                        <span className="savebutton-context">Update</span>
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpenss} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeCardModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete?
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      style={{
                        background: "#FF6600",
                        width: "80px",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "50px",
                      }}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => handleDeleteCard(cardId)}
                    >
                      Yes
                    </button>
                    <button
                      style={{
                        background: "#000",
                        width: "80px",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "50px",
                      }}
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeCardModal}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={openMove} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeMoveModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Move To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="flex fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon test">
                              {searchBucket ? (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faClose}
                                  onClick={handleBucketClear}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faSearch}
                                  onClick={handleBucketOpen}
                                />
                              )}
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                      {option.subBuckets &&
                                      option.subBuckets.length > 0 ? (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            position: "absolute",
                                            right: "17px",
                                            top: "14px",
                                          }}
                                        />
                                      ) : null}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeMoveModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCard(cardId)}
                            >
                              <span className="savebutton-context">Move</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openCopy} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeCopyModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "20px",
                                  color: "#707070",
                                  paddingTop: "8px",
                                }}
                                icon={faSearch}
                                onClick={handleBucketOpen}
                              />
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeCopyModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCard(cardId)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
    </div>
  );
}

export default SingleBookmark;
