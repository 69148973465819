import { React, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./cardDetail.scss";
import { faBell, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowUp,
  faStar,
  faChevronDown,
  faChevronRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import "../../components/Bookmarks/Bookmarks.scss";
import trashIcon from "../../assets/icons/New-Icons/Delete.svg";
import filledIcon from "../../assets/icons/filled_icon.svg";
import copyScript from "../../assets/icons/New-Icons/Copy-script.svg";
import likeIcon from "../../assets/icons/New-Icons/noun-like-1504384.svg";
import commentIcon from "../../assets/icons/New-Icons/noun-comments-665572.svg";
import viewIcon from "../../assets/icons/New-Icons/noun-view-4735231.svg";
import moveTo from "../../assets/icons/New-Icons/Move-to.svg";
import sourceLink from "../../assets/icons/external_link_icon.svg";
import galleryIcon from "../../assets/icons/galleryIcon.svg";
import copyIcon from "../../assets/icons/copyIcon.svg";
import linkIcon from "../../assets/icons/cardLink.svg";
import shareIcon from "../../assets/icons/shareLink.svg";
import resizeIcon from "../../assets/icons/resizeIcon.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import CalenderIcon from "../../assets/icons/calendar.svg";
import EditIcon from "../../assets/icons/edit.svg";
import PreviousIcon from "../../assets/icons/New-Icons/Previous.svg";
import NextIcon from "../../assets/icons/New-Icons/Next-icon.svg";
import FbIcon from "../../assets/images/fbnewlogo.svg";
import youtubeIcon from "../../assets/images/youtube.svg";
import tiktokIcon from "../../assets/images/download-tiktok.png";
import instagramIcon from "../../assets/images/instagram-icon-png.png";
import videoThumnail from "../../assets/images/video-thumbnail.jpg";
import twitterlogo from "../../assets/images/twitternewlogo.svg";
import defaultUser from "../../assets/images/default-user.png";
import question from "../../assets/icons/New-Icons/Group-66051.svg";
import refresh from "../../assets/icons/New-Icons/Refresh.svg";
import originalLink from "../../assets/icons/New-Icons/Original-link.svg";
import adfbicon from "../../assets/icons/New-Icons/Facebook.svg";
import adinstaicon from "../../assets/icons/New-Icons/Instagram.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import Union from "../../assets/icons/Union-19.svg";
import "react-toastify/dist/ReactToastify.css";
import Paragraph from "./Paragraph";
import Paragraphs from "./Paragraphs";
import FParagraph from "./Fparagraph";
// import DownloadButton from '../../assets/icons/orangeDownload.svg';
import DownloadButton from "../../assets/icons/blackDownload.svg";
// import LandingPage from '../../assets/images/landingPage.png';
import LandingPage from "../../assets/images/loading-landing.jpg";
import LandingPageNoPreview from "../../assets/images/Landing-page-No-preview-image.png";
import PurpleLink from "../../assets/icons/purpleLink.svg";
import SliderPopup from "./Sliderpopup.js";
import ZimzeeIcon from "../../assets/icons/logoSymbol.svg";
import BookmarksPopup from "../../assets/images/bookmarkspopup.png";
import FloatingMenu from "../../assets/icons/floatingchatmenu.png";
import FilesDetailIcon from "../../assets/icons/filesdetailicon.svg";
import BookmarkDetailIcon from "../../assets/icons/bookmarkdetail.svg";
// import resizeIcon from "../../assets/icons/resizeIcon.svg";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
import ShareCard from "./shareCard";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import FullScreen from "../../assets/icons/FullScreen.svg";
import RegionalScreen from "../../assets/icons/RegionalScreenshot.svg";
import Highlight from "../../assets/icons/HighlightText.svg";
import BookmarkPreview from "../../assets/icons/bookmarksPreviews.svg";
import FileDownload from "../../assets/images/FilesPreview.svg";
import { Ring, Roller } from "react-awesome-spinners";
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { faClose } from "@fortawesome/free-solid-svg-icons";
const VideoEmbed = ({ url }) => {
  const [dimensions, setDimensions] = useState({ height: null, width: null });
  useEffect(() => {
    const urlObj = new URL(url);
    const height = urlObj.searchParams.get('height');
    const width = urlObj.searchParams.get('width');

    if (height && width) {
      const heightValue = parseInt(height);
      const widthValue = parseInt(width);

      // Check if height is between 50% and 60% of width
      // if (heightValue >= 0.95 * widthValue && heightValue <= 1.05 * widthValue) {
      //   setDimensions({ height: 500, width: widthValue });
      // }else if (heightValue >= 0.5 * widthValue && heightValue <= 0.6 * widthValue) {
      //   setDimensions({ height: '300', width: "100% "});
      // } else {
      //   setDimensions({ height: heightValue, width: widthValue });
      // }
      setDimensions({ height: heightValue, width: widthValue });
    }
  }, [url]);
  return (
    <>
    {dimensions.height && dimensions.width ? (
         <iframe
         src={url}
         width={dimensions.width}
         height={dimensions.height}
         className="facebook-embed-iframe"
       ></iframe>
      ) : (
        <p>Loading iframe dimensions...</p>
      )}
     
    </>
  );

}

function CardDetail() {
  const navigate = useNavigate();
  const [subBucket, setSubBucket] = useState([]);
  const [code, setCode] = useState("Loading...");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useState(false);
  const [bookmarkhandlePopup, setBookmarkhandlePopup] = useState(false);
  const [imageUrlPopup, setimageUrlPopup] = useState("");
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingPopup, setIsPlayingPopup] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [searchBucket, setSearchBucket] = useState("");
  const [isVideoHeight, setIsVideoHeight] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [bucketCreated, setBucketCreated] = useState(false);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");
  const [openCopy, setOpenCopy] = useState(false);
  const [cardTitle, setCardTitle] = useState("");
  const cardId = useParams();
  const [buckets, setBuckets] = useState([]);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [hoveredOption, setHoveredOption] = useState(null);
  const [sourceUrl, setSourceUrl] = useState("");
  const [tagOptions, setTagOptions] = useState([]);
  const [inputValueSet, setInputValueSet] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  let [isOpenss, setIsOpens] = useState(false);
  const [fetchingCard, setFetchingCard] = useState(false);
  const [titleState, setTitleState] = useState(true);
  const [starCard, setStarCard] = useState(false);
  const [BucketSharePopup, setBucketSharePopup] = useState(false);
  const [shareBucketId, setShareBucketId] = useState("");
  const [selection, setSelection] = useState("");
  const [tags, setTags] = useState([]);
  const [mainBucket, setMainBucket] = useState({});
  const [BookmarkRestrict, setBookmarkRestrict] = useState(false);
  const [fileRestrict, setFileRestrict] = useState(false);
  const [intervalState, setIntervalState] = useState(false);
  const [sharedPermission, setSharedPermission] = useState("");
  const [UserPermission, setUserPermission] = useState("");
  const [SharedToken, setSharedToken] = useState("");
  const [loading, setLoading] = useState(true)

  const [imageSrc, setImageSrc] = useState(null);
  const [tagsWidth, setTagswidth] = useState("tagname-tagsinput");
  const[openCopyLink, setOpenCopyLink] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const [iOS, setiOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

  useEffect(() => {
    const fetchImage = async () => {
      if (subBucket?.landingPage?.status === true && subBucket?.landingPage?.url) {
        const exists = await checkImageExistence(subBucket?.landingPage?.url);
        setImageSrc(exists ? subBucket?.landingPage?.url : LandingPageNoPreview);
      } else if (subBucket?.landingPage?.status === false && subBucket?.landingPage?.sourceUrl) {
        // setImageSrc(LandingPage);
      } else {
        // console.log(subBucket.landingPage.url,subBucket.landingPage.status,subBucket.landingPage.sourceUrl,"landing page URL consoles");
        setImageSrc(LandingPageNoPreview);
      }
    };
    if(subBucket?.landingPage){
      fetchImage();
    }
    
  }, [subBucket?.landingPage?.url, subBucket?.landingPage?.status, subBucket?.landingPage?.sourceUrl]);
  
  useEffect(() => {
    console.log(tags, "all tags ...................");
    console.log(window.history.length,"window history")
  }, [tags]);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const cardtype = urlSearchParams.get("card");
    const shared = urlSearchParams.get("shared");
    const Permission = urlSearchParams.get("permission");
    document.querySelectorAll('.ad-lib-second-html .x6ikm8r.x10wlt62.xlyipyv.x5e6ka.xb2kyzz ._4ik4._4ik5').forEach(element => {
      if (!element.textContent.trim()) {
        element.style.display = 'none';
      }
    });
    if (Permission) {
      setUserPermission(Permission);
    }
    if (shared) {
      setSharedToken(true);
    }
    if (cardtype === "bookmark") {
      setBookmarkRestrict(true);
    } else if (cardtype === "file") {
      setFileRestrict(true);
    }
  }, []);
  const allowedImageTypes = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "bmp",
    "webp",
    "tiff",
    "ico",
    "png",
    "Image",
    "svg+xml",
    "x-icon",
    "zip",
    // Add more image types here if needed
  ];
  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        {
          color: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
      [
        {
          background: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const handleFocus = (e) => {
    e.stopPropagation();
    setIsTagOpen(true);
  };
  const fetchTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API}tags/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );
      console.log(response?.data?.data?.name, "tags list");
      setTagOptions(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);
  const handleCardLink = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://api.zimzee.com/api/cards/public-url",
        {
          itemId: cardId.id,
          isPublic: true,
          itemType: "Card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        if(iOS){
          setOpenCopyLink(true);
          setPublicUrl(response?.data?.data);
          // var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob([response?.data?.data], { type: "text/plain" }) )})];
          // navigator.clipboard.write(data).then(function() {
          //   // console.log("Copied to clipboard successfully!");
          //   toastify("success", "Public link is copied to clipboard");
          // }, function() {
          //   // console.error("Unable to write to clipboard. :-(");
          //   toastify("error", "Unable to write to clipboard. :-(" );
          // });
        }else{

            const tempInput = document.createElement("input");
            tempInput.value = response?.data?.data;

            // Append the input element to the DOM
            document.body.appendChild(tempInput);

            // Select the input element's text and copy it to clipboard
            tempInput.select();
            document.execCommand("copy");

            // Remove the temporary input element
            document.body.removeChild(tempInput);
            
            toastify("success", response.data.message);
            
        }
      }
    } catch (error) {
      console.error(error?.response?.data?.message, "error msg");
      toastify("error", error?.response?.data?.message);
    }
  };

  const handleCopyText = (url) => {
    var emailLink = document.querySelector('.publicUrlInput');
    console.log(emailLink,"copyEmailBtn"); 
    emailLink.select();
    var range = document.createRange();  
    range.selectNode(emailLink);  
    window.getSelection().addRange(range);  

    try {  
      // Now that we've selected the anchor text, execute the copy command  
      var successful = document.execCommand('copy');  
      var msg = successful ? 'successful' : 'unsuccessful';  
      console.log('Copy email command was ' + msg);  
      toastify("success", "Public link is copied to clipboard");
    } catch(err) {  
      console.log('Oops, unable to copy');  
      toastify("success", 'Oops, unable to copy');
    }  

    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported  
    window.getSelection().removeAllRanges();  
    setOpenCopyLink(false);
    setTimeout(() => {
      bucketUpdate();
    }, 1000);
}

  const closeCopyLinkModal = () => {
    setOpenCopyLink(false);
  }
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    console.log(code, "code lengthhhhhhhhhh");
  }, [code]);

  let Link = Quill.import("formats/link");
  Link.sanitize = function (value) {
    value = typeof value === "string" ? value : String(value);
    let link = value.trim();
    if (!/^https?:\/\//i.test(link)) {
      link = `http://${link}`;
    }
    return link;
  };

  const handleProcedureContentChange = (content, delta, source, editor) => {
    if (delta && delta.ops) {
      delta.ops.forEach((op) => {
        if (op.insert && op.insert.link) {
          op.insert = Link.sanitize(op.insert.link);
        }
      });
    }

    // Set the updated content to the state
    const cleanedContent = content.replace(/<p><br><\/p>/g, "");
    setCode(content);
  };
  const handleMoveto = () => {
    setOpenMove(true);
    setOptionSelect("MoveTo");
  };
  const filteredOptions = tagOptions.filter((option) =>
    option.name.toLowerCase().includes(inputValueSet.toLowerCase())
  );

  const handleSetInputValue = (e) => {
    console.log("width shrink");
    const widthValue = 'auto!important'; // You can set it dynamically based on some condition if needed
    // Get the element using document.querySelector
    const element = document.querySelector('.react-tagsinput-input');
    // Apply the width style
    if (element) {
      element.style.width = 'auto!important';
      console.log(element,"element");
    }
    setTagswidth("tagname-changetagsinput");
    setInputValueSet(e);
    setIsTagOpen(true);
  };

  const handleCopyto = () => {
    setOpenCopy(true);
    setOptionSelect("CopyTo");
  };
  const handleBlur = () => {
    setIsTagOpen(false);
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const newTag = selectedOption.name;
      setTags([...tags, newTag]);
      handleTagsChange([...tags, newTag]);
      handleBlur();
    }
  };
  const closeSharePop = () => {
    console.log("close share pop");
    setBucketSharePopup(false);
  };
  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareBucketId(id);
    setBucketSharePopup(true);
  };
  const handleDelete = () => {
    setIsOpens(true);
  };
  const closeMoveModal = () => {
    setOpenMove(false);
    setSearchBucket("");
  };
  const handleOnChangeTitle = (e) => {
    setCardTitle(e.target.value);
  };
  const handleOnBlurTitle = () => {
    handleCardTitle();
    // setTitleState(true);
  };
  const handleCardTitle = () => {
    const token = localStorage.getItem("token");
    try {
      const response = axios.patch(
        "https://api.zimzee.com/api/cards/update",
        { cardId: cardId.id, title: cardTitle },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      bucketUpdate();
      setTitleState(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditTitle = () => {
    setTitleState(false);
    setCardTitle(subBucket?.title);
    setTimeout(() => {
      document.getElementsByClassName("carddetailfile-content")[0].focus();
    }, 100);
  };
  const handleFileDownload=()=>{

  }
  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedHTML = clipboardData.getData("text/html");
    const quill = this.reactQuillRef.getEditor();
    const selection = quill.getSelection();
    const index = selection ? selection.index : 0;
    const delta = quill.clipboard.convert(pastedHTML);
    quill.updateContents(delta, Quill.sources.USER);
    quill.setSelection(index + delta.length(), Quill.sources.SILENT);
  };
  const handleKeyUp = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleEditClick = () => {
    handleEditorFocus();
  };
  const handleEditorFocus = () => {
    setReadOnly(false);
    setIsEditorFocused(true);
    setIsActive(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };

  const handleEditorClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };

  const handleEditorBlur = async () => {
    setIsEditorFocused(false);
    const token = localStorage.getItem("token");
    console.log(cardId.id, "cardId");
    await axios
      .get(`${process.env.REACT_APP_API}cards/single-card/${cardId.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setIsLoading(false);
        setSubBucket(response?.data?.data);
        setCode(response?.data?.data?.note ?? "Add Notes...");
        setIsEditorFocused(false);
        const elements = document.getElementsByClassName("ql-toolbar");
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove("my-class-selected");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEditorCancel = async () => {
    const token = localStorage.getItem("token");
    console.log(cardId.id, "cardId");
    await axios
      .get(`${process.env.REACT_APP_API}cards/single-card/${cardId.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setIsLoading(false);
        setSubBucket(response?.data?.data);
        setCode(response?.data?.data?.note ?? "Add Notes...");
        setIsEditorFocused(false);
        const elements = document.getElementsByClassName("ql-toolbar");
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove("my-class-selected");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log("Child clicked");
  };
  const handleBucketOpen = () => {
    setIsBucketOpen(!isBucketOpen);
  };
  const handleBucketClear = () => {
    setSearchBucket("");
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleDownloadFile = (type) => {
    setIsLoading(true)
    console.log(type, "File Type");
    // return
    const token = localStorage.getItem("token");
    if (subBucket?.content?.imageUrl[0].url) {
      axios
        .post(
          `${process.env.REACT_APP_API}cards/download-file`,
          { fileUrl: subBucket?.content?.imageUrl[0].url },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // You should read the response data as an array buffer
          const base64Data = response?.data?.data?.fileBase64;
          const byteCharacters = atob(base64Data);

          // Convert the data to an array of bytes
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type });

          // Create an Object URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `image.${type}`; // Set the desired filename and file extension
          a.style.display = "none";
          document.body.appendChild(a);

          // Simulate a click on the anchor to trigger the download
          a.click();

          // Clean up by revoking the Object URL
          URL.revokeObjectURL(url);
          setIsLoading(false)
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };
  const handleSubBucketSelectChange = (subbucket, text) => {
    // if (BookmarkRestrict === true && text === "copy") {
    //   toastify("error", "To copy bookmarks, you must select a main bucket");
    // } else if (BookmarkRestrict === true && text === "move") {
    //   toastify("error", "To move bookmarks, you must select a main bucket");
    // } else if (fileRestrict === true && text === "copy") {
    //   toastify("error", "To copy files, you must select a main bucket");
    // } else if (fileRestrict === true && text === "move") {
    //   toastify("error", "To move files, you must select a main bucket");
    // } else {
      const newBucket = subbucket._id;
      setSelectedBucket(newBucket);
      setSearchBucket(subbucket.name);
      setIsBucketOpen(false);
    // }

    // setHoveredOption(hoveredOption);
    // handleTagsChange([...tags, newTag]);
    // handleBlur()
  };
  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });
  const handleBucketInputChange = (event) => {
    const inputText = event.target.value;
    setSearchBucket(inputText.toLowerCase());
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleUpdateCardMenu = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        {
          optionType: optionSelect,
          destinationBucketID: selectedBucket,
          cardId: Id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      bucketUpdate();
      toastify("success", response.data.message);
      if (optionSelect === "MoveTo") {
        closeMoveModal();
      }
      if (optionSelect === "CopyTo") {
        closeCopyModal();
      }
      setSearchBucket("");
      setSelectedBucket("");
      // cardUpdate();
    } catch (error) {
      console.error(error);
      setSelectedBucket("");
      toastify("error", error.response.data.message);
    }
  };

  const handlePopup = () => {
    setPopup(true);
    setIsPlayingPopup(false);
  };

  const handlePopupFullPage = (url) => {
    window.open(url, "_blank");
  };

  const closeCopyModal = () => {
    setOpenCopy(false);
    setSearchBucket("");
  };
  function closeCardModal() {
    setIsOpens(false);
  }
  const handlePlayVideo = (event) => {
    // document.querySelector('video').play();
    console.log("paly evenet");
    if (isPlaying) {
      document.querySelector(".video-content-inner video").pause();
    } else {
      document.querySelector(".video-content-inner video").play();
    }
    setIsPlaying(!isPlaying);
    event.stopPropagation();
  };

  const handlePlayVideoPopup = (event) => {
    // document.querySelector('video').play();
    if (isPlayingPopup) {
      document.querySelector(".bucketCreationPopup video").pause();
    } else {
      document.querySelector(".bucketCreationPopup video").play();
    }
    setIsPlayingPopup(!isPlayingPopup);
    event.stopPropagation();
  };

  const handleImageClick = (imageUrl) => {
    console.log(imageUrl, "imageUrl for slider specific image");
    setimageUrlPopup(imageUrl);
    setBookmarkhandlePopup(true);
  };
  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };
  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  function closePopup() {
    setPopup(false);
  }

  function closebookmarkPopup() {
    setBookmarkhandlePopup(false);
  }
  const goBack = () => {
    console.log("goback works");
    navigate(-1);
  };
  const handleDeleteCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: Id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // navigate(-1,{replace:true});

      toastify("success", response.data.message);
      closeCardModal();
      
      if (window.history.length >1) {
        console.log('Using history API');
        window.history.go(-1);
      } else {
        console.log('History API not supported');
        if (subBucket?.bucketId?.parent === false) {
          console.log('Navigating to /app/sub-buckets/', subBucket?.bucketId?._id);
          navigate(`/app/sub-buckets/${subBucket?.bucketId?._id}?bucketId=${mainBucket?._id}&name=${mainBucket?.name}`);
        } else {
          console.log('Navigating to /app/buckets/', subBucket?.bucketId?._id);
          navigate(`/app/buckets/${subBucket?.bucketId?._id}`);
        }
      }
        
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };
  const handleClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleStarCard = () => {
    StarredCard(!starCard);
  };
  const StarredCard = async (star) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        { cardId: cardId.id, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      bucketUpdate();
    } catch (error) {
      console.error(error);
    }
  };

  // let org_html = document.getElementsByClassName("x1n2onr6").innerHTML;
  // let new_html = "<div class='slidesInner'>" + org_html + "</div>";
  // document.getElementsByClassName("toggle-view-icon").innerHTML = new_html;
  // console.log('defsdfsdf' + new_html);

  // const element = document.getElementsByClassName('ql-picker-label');
  // if (element) {
  //     for (let i = 0; i < element.length; i++) {
  //         element[i].addEventListener('click', handleClick);
  //       }
  // //   element.addEventListener('click', handleClick);
  // }
  useEffect(() => {
    // api url
    // Function to make the API call
    const fetchData = async (intervalId = "") => {
      const token = localStorage.getItem("token");
      console.log(cardId.id, "cardId");
      await axios
        .get(`${process.env.REACT_APP_API}cards/single-card/${cardId.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setBucketCreated(false);
          setIsLoading(false);
          // if (
          //   (intervalId && response?.data?.data?.landingPage?.status) ||
          //   !response?.data?.data?.landingPage?.sourceUrl
          // ) {
          //   clearInterval(intervalId);
          // }
          const allTags = response?.data?.data?.tags?.map((tag) => tag.name);
          setTags(allTags);
          setSubBucket(response?.data?.data);
          setCode(response?.data?.data?.note ?? "Add Notes...");
          console.log(response?.data?.data.content.sourceUrl);
          console.log(
            response?.data?.data?.landingPage.sourceUrl,
            "landing page object"
          );
          setSourceUrl(response?.data?.data.content.sourceUrl);
          if(!response?.data?.data.content.sourceUrl.includes('facebook.com') && response?.data?.data.cardType == 'facebook'){
            setSourceUrl('https://www.facebook.com'+response?.data?.data.content.sourceUrl);
          }
          

          setImageUrl(response?.data?.data.content.imageUrl?.url);
          setStarCard(response?.data?.data.starred);
          setMainBucket(response?.data?.data?.parentBukcet);
          setIntervalState(!intervalState);
          const sharedArray = response?.data?.data?.shared;
          let isPublic = false; // Initialize the isPublic state to false

          if (sharedArray && sharedArray.length > 0) {
            // Iterate through the array and check each object's isPublic property
            for (const sharedItem of sharedArray) {
              if (sharedItem.isPublic === true) {
                isPublic = true; // Set the isPublic state to true if any object has isPublic set to true
                break; // Exit the loop early since we found a match
              }
            }
            setSharedPermission(isPublic);
          }
        })
        .catch(function (error) {
          console.log(error);
          if(error?.response?.data?.statusCode){
            if(error?.response?.data?.statusCode == 404){
              navigate(`/notFound`)
            }
          }
        });
    };
    fetchData();
  }, [bucketCreated]);

  useEffect(() => {
    const fetchDataInterval = async (intervalId = "") => {
      const token = localStorage.getItem("token");
      console.log(cardId.id, "cardId");
      if (intervalId) {
        await axios
          .get(`${process.env.REACT_APP_API}cards/single-card/${cardId.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            setSubBucket(response?.data?.data);
            if (
              (intervalId && response?.data?.data?.landingPage?.status) ||
              !response?.data?.data?.landingPage?.sourceUrl
            ) {
              console.log(response.data.data, intervalId);
              // setIntervalState(!intervalState);
              clearInterval(intervalId);
            }
          })
          .catch(function (error) {
            console.log(error?.response?.data);
            if(error?.response?.data?.statusCode){
              if(error?.response?.data?.statusCode == 404){
                navigate(`/notFound`)
              }
            }
          });
      }
    };

    const intervalId = setInterval(() => {
      // console.log(subBucket?.landingPage?.status, "statussss");
      console.log(intervalId, "intervalId statussssss");
      fetchDataInterval(intervalId);
    }, 8000); // 5000 milliseconds interval (adjust as needed)
    // (subBucket?.landingPage?.status && subBucket?.landingPage?.sourceUrl) || (!subBucket?.landingPage?.sourceUrl)
    if (subBucket?.landingPage?.status) {
      console.log(subBucket.landingPage.status, "status");
      console.log("ssssssssssssssssssssssssss", intervalId);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Cleanup function
  }, [intervalState]);

  function bucketUpdate() {
    setBucketCreated(!bucketCreated);
  }

  // if(subBucket?.landingPage?.status == false){
  //     const intervalId = setInterval(() => {
  //         bucketUpdate();
  //       }, 5000);
  // }

  const filterDropDown = [
    { name: "Date Swiped", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
    { name: "Manual", href: "#" },
  ];

  const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleFilterText = (text) => {
    setFiltersText(text);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!fetchingCard) {
        // Check if the cursor is not in certain input fields
        const cursorNotInInputField = document.activeElement.tagName !== "INPUT";
        const elements = document.getElementsByClassName("fullpageClick active");
        console.log(elements.length)
        if (cursorNotInInputField && elements.length == 0) {
          if (event.key === "ArrowRight") {
            FetchCard(1); // Fetch next card when right arrow key is pressed
          } else if (event.key === "ArrowLeft") {
            FetchCard(0); // Fetch previous card when left arrow key is pressed
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [fetchingCard]);

  const FetchCard = (value) => {
    if (!fetchingCard) {
      setFetchingCard(true);
      console.log(selection, "selection on api calll");
      const token = localStorage.getItem("token");
      console.log(cardId.id, "cardId");
      try {
        const response = axios
          .get(
            `${process.env.REACT_APP_API}cards/single-card/${cardId.id}?${
              value === 1 ? "next" : "prev"
            }=true`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            console.log(response?.data?.data._id, "next card id");
            console.log(response?.data?.data.cardType, "next card type");
            if (response) {
              const cardId = response?.data?.data._id;
              if (cardId) {
                navigate(`/app/card-detail/${cardId}`);
                setBucketCreated(true);
              }
            }
            setTimeout(() => {
              setFetchingCard(false);
            }, 1000);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  // const handleChangeCardClick = (value) => {
  //   console.log(value,"selectedddddd")
  //   if(value===1){
  //     setSelection("next")
  //   }
  //   else{
  //     setSelection("previous")
  //   }
  //    // Clear any existing interval
  //   FetchCard(); // Fetch data immediately

  //    // Store the new intervalId in the state
  // };
  const updateCard = async (cardId) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        { cardId: cardId, note: code },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toastify("success", response.data.message);
      console.log(response);
      bucketUpdate();
      setReadOnly(true);
      setIsEditorFocused(false);
      setIsActive(false);
      const elements = document.getElementsByClassName("ql-toolbar");
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("my-class-selected");
      }
    } catch (error) {
      setIsActive(false);
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  async function checkImageExistence(url) {
    return new Promise(async (resolve) => {
      try {
        var img = new Image();
        img.onload = function () {
          console.log(url, "url checkImageExistence");
          resolve(true);
        };
        img.onerror = function () {
          console.log(url, "url checkImageExistence error");
          resolve(false);
        };
        img.src = url;
      } catch (error) {
        console.error("Error checking image existence:", error);
        resolve(false);
      }
    });
  }
  // checkImageExistence(subBucket?.landingPage?.url, function (exists) {
  //   if (exists) {
  //     console.log("image exists");
  //     // return subBucket?.landingPage?.url;
  //   } else {
  //     console.log("image not exists");
  //     // return LandingPageNoPreview;
  //   }
  // })

  let videoIframe = "";
  let videoIframePopup = "";
  if (
    subBucket?.content?.imageUrl.length > 0 &&
    subBucket?.content?.imageUrl[0].urlType == "Video" &&
    subBucket?.content?.html &&
    subBucket?.content?.imageUrl[0].url
  ) {
    videoIframe = subBucket?.content?.html;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = videoIframe;
    // tempElement.find('iframe').attr('')
    const iframes = tempElement.querySelector("iframe");
    if (iframes) {
      const videoElement = document.createElement("video");
      videoElement.controls = isPlaying ? true : false;
      // videoElement.autoplay = true;

      const sourceElement = document.createElement("source");
      sourceElement.src = subBucket?.content?.imageUrl[0].url;
      sourceElement.type = "video/mp4";

      videoElement.appendChild(sourceElement);
      const playIcon = document.createElement("div");
      playIcon.className = "play-icon";
      playIcon.innerHTML = "&#9658;";

      // Wrap the video element with a container div
      const videoContainer = document.createElement("div");
      videoContainer.className = "video-container";
      videoContainer.appendChild(videoElement);
      // videoContainer.appendChild(playIcon);

      // Replace the iframe with the video element
      iframes.parentNode.replaceChild(videoContainer, iframes);
      // const myTimeout = setTimeout(myStopFunction, 1000);

      //     function myStopFunction() {
      //         console.log(document.getElementsByTagName('video')[0].clientHeight , "video height");
      //         setIsVideoHeight(document.getElementsByTagName('video')[0].clientHeight);
      //     }
    }

    // Get the updated HTML content from the temporary container
    const updatedHtml = tempElement.innerHTML;
    console.log(updatedHtml, "updatedHtml");
    videoIframe = updatedHtml;

    videoIframePopup = subBucket?.content?.html;
    const tempElementPopup = document.createElement("div");
    tempElementPopup.innerHTML = videoIframePopup;
    // tempElement.find('iframe').attr('')
    const iframesPopup = tempElementPopup.querySelector("iframe");
    if (iframesPopup) {
      const videoElementPopup = document.createElement("video");
      videoElementPopup.controls = isPlayingPopup ? true : false;
      // videoElement.autoplay = true;

      const sourceElementPopup = document.createElement("source");
      sourceElementPopup.src = subBucket?.content?.imageUrl[0].url;
      sourceElementPopup.type = "video/mp4";

      videoElementPopup.appendChild(sourceElementPopup);
      // Wrap the video element with a container div
      const videoContainerPopup = document.createElement("div");
      videoContainerPopup.className = "video-container";
      videoContainerPopup.appendChild(videoElementPopup);
      // videoContainer.appendChild(playIcon);

      // Replace the iframe with the video element
      iframesPopup.parentNode.replaceChild(videoContainerPopup, iframesPopup);
    }

    // Get the updated HTML content from the temporary container
    const updatedHtmlPopup = tempElementPopup.innerHTML;
    console.log(updatedHtmlPopup, "updatedHtmlPopup");
    videoIframePopup = updatedHtmlPopup;
  }
  const tagStyle = {
    backgroundColor: "#eadbff", // Set the background color here
    color: "white", // Optionally, set text color
    padding: "4px 8px", // Optionally, adjust padding
  };
  const handleTagsChange = async (newTags) => {
    // TODO: add api call to update tags.
    setTags(newTags);
    setInputValueSet("");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    const payload = newTags?.map((tag) => {
      return {
        name: tag,
        // parent: true,
        // subTags: [],
        // userId: userData?._id,
      };
    });
    const response = await axios.patch(
      "https://api.zimzee.com/api/cards/update",
      { cardId: cardId.id, tags: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const inputElement = document.querySelector(".react-tagsinput-input");
    const widthValue = '100px!important'; // You can set it dynamically based on some condition if needed
    // Get the element using document.querySelector
    // Check if the input element exists before calling focus
    if (inputElement) {
      inputElement.style.width = '100px!important';
      console.log(inputElement,"width increase");
      inputElement.focus();
    }
    setTagswidth("tagname-tagsinput")
    console.log(response);
    console.log("width increase");
    bucketUpdate();
  };

  return (
    <div className="carddetail cardDetailHolder" onClick={handleBlur}>
         {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
      <div className="carddetail-desktopsection">
        <div className="cards">
          <div className="card-header flex flex-col md:flex-row justify-start md:justify-between items-center w-full mb-3 card-header-desktop">
            <div className="cardHeaderContent">
              <div className="bucketNameTag">
                <p className="bucketname-textcontent">
                  {subBucket.cardType == "File" ? (
                    <a href="/app/files">Files</a>
                  ) : subBucket.cardType == "bookmark" ? (
                    <a href="/app/bookmarks">Bookmarks</a>
                  ) : (
                    <>
                      <a href="/app/buckets" style={{paddingRight: "5px"}}>My Bucket</a>
                      <span className="uxtext-content">
                      &nbsp;/&nbsp;
                        {mainBucket &&
                        subBucket?.cardType != "File" &&
                        subBucket?.cardType != "bookmark" ? (
                          <a href={"/app/buckets/" + mainBucket?._id}>
                            {mainBucket?.name} /
                          </a>
                        ) : null}
                        {subBucket &&
                        mainBucket &&
                        subBucket?.cardType != "File" &&
                        subBucket?.cardType != "bookmark" ? (
                          <a
                            href={`/app/sub-buckets/${subBucket?.bucketId?._id}?bucketId=${mainBucket?._id}&name=${mainBucket?.name}`}
                          >
                            &nbsp;{subBucket?.bucketId?.name}
                          </a>
                        ) : (
                          <a href={"/app/buckets/" + subBucket?.bucketId?._id}>
                            {subBucket?.bucketId?.name}
                          </a>
                        )}
                      </span>
                    </>
                  )}{" "}
                  <span className="uxtext-content">
                    /{" "}
                    <a href={"/app/card-detail/" + subBucket?._id}>
                      {subBucket?.title}
                    </a>
                  </span>
                </p>
              </div>
              {/* <div className="bucketNameTag">
                            <p className="bucketname-textcontent">{subBucket?.bucketId?.name}</p>
                        </div> */}
              <div
                className={`flex actionsHolder ${
                  SharedToken ? "pointer-events-none" : ""
                }`}
                style={{display:"none"}}
              >
                <div className="">
                  <a
                    href="#"
                    className="flex items-center"
                    onClick={handleMoveto}
                  >
                    {/* <FontAwesomeIcon
                      className="transform rotate-45"
                      icon={faArrowUp}
                    /> */}
                    <img className="w-6" src={moveTo} alt="copy-to" />
                    <span className="actionicon-content ml-3">Move to</span>
                  </a>
                </div>
                <div>
                  <a
                    href={`javascript:void(0);`}
                    className="flex items-center pl-[24px]"
                    onClick={handleDelete}
                  >
                    <img
                      className="w-4 trash-icon"
                      src={trashIcon}
                      alt="trashIcon"
                    />
                    <span className="actionicon-content pl-3">Delete</span>
                  </a>
                </div>
                <div>
                  <a
                    href="#"
                    className="flex items-center pl-[24px]"
                    onClick={handleCopyto}
                  >
                    <img className="w-6" src={copyScript} alt="copy-to" />
                    <span className="actionicon-content pl-3">Copy to</span>
                  </a>
                </div>
                {subBucket?.cardType != "File" ? (
                  <div className="">
                    <a
                      href={`${sourceUrl}`}
                      target="_blank"
                      className="flex items-center pl-[24px]"
                    >
                      <img className="w-5" src={originalLink} alt="source-link" />
                      <span className="actionicon-content pl-3">
                        Original Link
                      </span>
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {/* <div>
                                    <a href={`${imageUrl}`} target ="_blank" className="flex items-center pl-[24px]">
                                    <img src={galleryIcon} alt="source-image" />
                                    <span className="actionicon-content pl-3">Image Link</span>
                                </a>
                                </div> */}
              </div>

              {/* <div className="bucketName flex items-center">
                            <img className="mr-[7px] inner-logo" src={(subBucket?.cardType)? (subBucket.cardType == 'twitter' ?  twitterlogo : subBucket.cardType == 'facebook' || subBucket.cardType == 'Facebook' ? FbIcon : defaultUser):("")} alt="Icon"/>
                            <h4 className="bucketnametitle-content mr-[30px]">{subBucket?.title}</h4>
                            <img className="mr-[7px] next-icons" src={PreviousIcon} alt="PreviousIcon"/>
                            <img className="ml-[7px] next-icons" src={NextIcon} alt="NextIcon"/>
                        </div> */}
            </div>
            <div className="carddetail-endcard">
              <div
                className={`sharedAction flex items-center ${
                  SharedToken ? "pointer-events-none" : ""
                }`}
              >
                <div className="flex">
                  <img
                    className="topinner-logo "
                    src={
                      subBucket?.cardType
                        ? subBucket.cardType == "twitter"
                          ? twitterlogo
                          : subBucket.cardType == "facebook" ||
                            subBucket.cardType == "Facebook"
                          ? FbIcon
                          : subBucket.cardType == "screenshoot"
                          ? ZimzeeIcon
                          : subBucket.cardType == "fullScreen"
                          ? FullScreen
                          : subBucket.cardType == "partScreen"
                          ? RegionalScreen
                          : subBucket.cardType == "highlightText"
                          ? Highlight
                          : subBucket.cardType == "youtube"
                          ? youtubeIcon
                          : subBucket.cardType == "TikTok"
                          ? tiktokIcon
                          : subBucket.cardType == "instagram"
                          ? instagramIcon
                          : subBucket.cardType == "File"
                          ? FilesDetailIcon
                          : subBucket.cardType == "bookmark"
                          ? BookmarkDetailIcon
                          : defaultUser
                        : ""
                    }
                    alt="Icon"
                    style={{cursor:"default"}}
                  />
                </div>
                {/* <div className="flex"><img src={FilesDetailIcon} className="topinner-logo" alt="FilesDetailIcon" /></div> */}
                {sharedPermission === true ? (
                  <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl mobile-shared-permission">
                    <p className="publicytext-content">Publicly Shared</p>
                    <span className="bg-white rounded-[50%] p-1 ml-2">
                      <img src={copyIcon} alt="copy-icon" />
                    </span>
                  </div>
                ) : null}
                {subBucket?.cardType === "File" ? null : (
                  <div className="w-6 mx-2">
                    <img
                      className="cardicon-imgcontent"
                      src={linkIcon}
                      alt="link-icon"
                      onClick={handleCardLink}
                    />
                  </div>
                )}

                <div>
                  <div
                    className="inviteBtn"
                    onClick={() => handleSharePop(cardId.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    <a
                      style={{
                        filter:
                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                      }}
                      className="btn-invite rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg"
                      href="#"
                    >
                      @ Invite People
                    </a>
                  </div>
                </div>
                {/* <div className="w-7 mx-2">
                            <img className="cardicon-imgcontent" src={shareIcon} alt="shareIcon" />
                        </div> */}
              </div>
            </div>
          </div>
          <hr />

          <div className="cardHeaderDetails flex pt-3" style={{display:"none"}}>
            <div className="display-content" style={{display:"none"}}>
              <ul className="flex flex-wrap items-center">
                <li className="text-[14px] lg:text-[17px]">
                  <a className="flex items-center" href="/">
                    <img
                      src={CalenderIcon}
                      alt="calenderIcon"
                      className="w-[22px]"
                    />{" "}
                    <span className="createdtext-content pl-2">
                      Created{" "}
                      {moment(subBucket?.createdAt).format(
                        "MMM D, YYYY @ hh:mm A"
                      )}
                    </span>
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="display-content" style={{display:"none"}}>
              <ul className="flex flex-wrap items-center">
                <li className="text-[14px] lg:text-[17px]">
                  <a className="flex items-center" href="/">
                    <img
                      src={CalenderIcon}
                      alt="calenderIcon"
                      className="w-[22px]"
                    />{" "}
                    <span className="createdtext-content pl-2">
                      Last updated{" "}
                      {moment(subBucket?.updatedAt).format(
                        "MMM D, YYYY @ hh:mm A"
                      )}
                    </span>
                  </a>{" "}
                </li>
              </ul>
            </div>
            {subBucket?.cardType === "File" ||
            subBucket?.cardType === "bookmark" ? null : (
              <>
                {"Previous"}
                <img
                  className="mr-[7px] next-icons"
                  src={PreviousIcon}
                  alt="PreviousIcon"
                  onClick={() => FetchCard(1)}
                />
                {"Next"}
                <img
                  className="ml-[7px] next-icons"
                  src={NextIcon}
                  alt="NextIcon"
                  onClick={() => FetchCard(0)}
                />
              </>
            )}
          </div>
        </div>
        {BucketSharePopup && (
          <div
            className="filesuploadpopup relative !z-[999]"
            style={{ height: "100%" }}
            onClick={closeSharePop}
          >
            <div
              className="subinviteupload-section"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Share</div>
                <div
                  className="dismissSubfiles-content"
                  onClick={closeSharePop}
                >
                  <img src={BlackDismiss} alt="dismissButton" />
                </div>
              </div>
              <ShareCard
                shareBucketId={shareBucketId}
                closeSharePop={closeSharePop}
                cardsRefresh={bucketUpdate}
              />
            </div>
          </div>
        )}
        <div className="secondsection-content">
          <div className="cardData cardtextwhole-content w-2/3 width-50">
            <div className="cardHtmlnew-content">
              <div className="cardheader-text content card-header ">
                <div className="cardHeaderContents">
                  <div className="titlebucket-content bucketName flex items-center">
                    <div className="flex">
                      <img
                        className="mr-[5px] inner-logo border-rounded"
                        src={
                          subBucket?.cardType
                            ? subBucket.cardType == "twitter"
                              ? subBucket.postDetail
                                ? subBucket.postDetail.imageUrl
                                : twitterlogo
                              : subBucket.cardType == "facebook" ||
                                subBucket.cardType == "Facebook"
                              ? subBucket.postDetail
                                ? subBucket.postDetail.imageUrl
                                : FbIcon
                              : subBucket.cardType == "screenshoot"
                              ? ZimzeeIcon
                              : subBucket.cardType == "fullScreen"
                              ? FullScreen
                              : subBucket.cardType == "partScreen"
                              ? RegionalScreen
                              : subBucket.cardType == "highlightText"
                              ? Highlight
                              : subBucket.cardType == "youtube"
                              ? subBucket.postDetail
                                ? subBucket.postDetail.imageUrl
                                : youtubeIcon
                              : subBucket.cardType == "TikTok"
                              ? subBucket.postDetail
                              ? subBucket.postDetail.imageUrl ?
                              subBucket.postDetail.imageUrl
                              : tiktokIcon
                              : tiktokIcon
                              : subBucket.cardType == "instagram"
                              ? subBucket.postDetail
                                ? subBucket.postDetail.imageUrl
                                : instagramIcon
                              : subBucket.cardType == "File"
                              ? FilesDetailIcon
                              : subBucket.cardType == "bookmark"
                              ? BookmarkDetailIcon
                              : defaultUser
                            : ""
                        }
                        alt="Icon"
                      />
                      {titleState ? (
                        <h4 className="buckettitle-textcontent">
                          {subBucket?.title}
                        </h4>
                      ) : (
                        <>
                          <input
                            type="text"
                            value={cardTitle}
                            onChange={handleOnChangeTitle}
                            onBlur={handleOnBlurTitle}
                            className="carddetailfile-content"
                            style={{
                              fontSize: "18px",
                              outline: "none",
                              border: "none",
                              padding: "0 10px",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          />
                        </>
                      )}
                    </div>
                    {/* <div className="flex"><img src={FilesDetailIcon} className="topinner-logodetai" alt="FilesDetailIcon" /></div> */}
                    <div
                      className={`bucketActions flex iconsfilesspace-content ${
                        SharedToken ? "pointer-events-none" : ""
                      }`}
                    >
                      {/* <span onClick={handlePopup}>
                                <img className="resizeicon-imgcontent" src={resizeIcon} />
                            </span> */}
                      <span>
                        {titleState ? (
                          <img
                            className="subbuckets-dark"
                            src={EditIcon}
                            alt="editIcon"
                            onClick={handleEditTitle}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "20px",
                              marginLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                              marginRight: "20px",
                              cursor: "pointer",
                            }}
                            icon={faCheck}
                            onClick={handleCardTitle}
                            color="gray"
                          />
                        )}
                      </span>
                      <span onClick={handleStarCard}>
                        {starCard ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            color="gold"
                            style={{
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-[#989898]"
                            icon={faStar}
                            style={{
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        {/* <FontAwesomeIcon className="px-3 text-[#707070]" icon={faTrash} /> */}
                      </span>
                      {subBucket.cardType == "bookmark" ? (
                        <a href={`${sourceUrl}`} target="_blank">
                          <span>
                            <img
                              className="resizeicons-imgcontent"
                              src={resizeIcon}
                              style={{
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "20px",
                                paddingTop: "2px",
                              }}
                            />
                          </span>
                        </a>
                      ) : subBucket.cardType === "File" ? null :
                      (
                        <span onClick={handlePopup}>
                          <img
                            className="resizeicons-imgcontent"
                            src={resizeIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="cardHeaderDetails">
                        <ul className="flex flex-wrap items-center">
                            <li className="pr-2 text-[15px]"><a className="flex items-center" href="/"><span className="createdbucket-content">Updated {moment(subBucket?.createdAt).format('MMM D, YYYY @ hh:mm A')}</span></a> </li>        
                        </ul>
                    </div> */}
                </div>
              </div>
              {/* <div className="cardContent cardDetailContent" onClick={handlePopup}>  */}
              {/* <button onClick={handlePopup}>Open Modal</button> */}
              {subBucket?.content ? (
                subBucket.content.imageUrl.length > 0 &&
                subBucket?.content?.imageUrl[0].urlType == "Image" &&
                subBucket.content.html ? (
                  <div className="cardContent cardDetailContent">
                    <div className="cardImage w-full mt-6 atest">
                      {subBucket?.cardType ? (
                        subBucket.cardType == "twitter" ? (
                          <div className="css-146c3p1">
                            {parse(subBucket.content.html)}
                          </div>
                        ) : subBucket.cardType == "facebook" ? (
                          subBucket?.content?.imageUrl[0].isAdLibrary ? (
                            <>
                              <div className="ad-lib-first-html">
                                {parse(subBucket.content.html)}
                              </div>
                            </>
                          ) : (
                            <div className="first-html">
                              <Paragraphs
                                text={subBucket.content.html}
                                maxLength={380}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      <div
                        className={`container fb-image ${
                          subBucket?.cardType == "youtube"
                            ? "youtube-container"
                            : ""
                        }`}
                      >
                        {subBucket?.content?.imageUrl.length > 0 &&
                        subBucket?.content?.imageUrl[0].urlType == "Image" &&
                        subBucket?.cardType !== "youtube" ? (
                          subBucket.cardType == "bookmark" ? (
                            subBucket?.content?.imageUrl[0]?.url?
                            <a href={`${sourceUrl}`} target="_blank">
                              <img
                                className="w-full dgdg"
                                src={subBucket?.content?.imageUrl[0].url}
                                alt="card-image"
                              />
                            </a> : 
                            <a href={`${sourceUrl}`} target="_blank">
                            <img
                              className="w-full dgdg"
                              src={BookmarkPreview}
                              alt="card-image"
                            />
                          </a>
                          ) : (
                            subBucket?.cardType == "fullScreen" ? 
                            subBucket?.content?.imageUrl?.map((image,index) => (
                              <img
                              className="w-full dgdg"
                              src={image.url}
                              alt="card-image"
                              onClick={handlePopup}
                            />
                          ))
                         :
                            <img
                              className="w-full dgdg"
                              src={subBucket?.content?.imageUrl[0].url}
                              alt="card-image"
                              onClick={handlePopup}
                            />
                          )
                        ) : subBucket?.cardType == "youtube" ? (
                          <iframe
                            src={
                              `https://www.youtube.com/embed/` +
                              subBucket?.content?.sourceUrl
                                .split("v=")[1]
                                .split("&")[0]
                            }
                            width="100%"
                            height="500"
                          ></iframe>
                        ) : (
                          ""
                        )}
                      </div>
                      {subBucket?.cardType ? (
                        subBucket.cardType == "twitter" ? (
                          ""
                        ) : subBucket.cardType == "facebook" ? (
                          subBucket?.content?.imageUrl[0].isAdLibrary ? (
                            <>
                              <div className="ad-lib-second-html">
                                {parse(subBucket.content.html)}
                              </div>
                            </>
                          ) : (
                            <div className="second-html">
                              {parse(subBucket.content.html)}
                            </div>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : subBucket?.content?.imageUrl.length > 0 &&
                  subBucket?.content?.imageUrl[0].urlType == "Video" &&
                  subBucket?.content?.html == " " ? (
                  <div
                    className={`cardContent cardDetailContent video video-content-inner ${
                      isPlaying ? "active" : ""
                    }`}
                  >
                    <Paragraphs
                      text={subBucket?.content?.html}
                      maxLength={380}
                    />
                    {/* {parse(videoIframe)} */}
                    <div className="video-container">
                      {isPlaying ? (
                        <video controls>
                          <source
                            src={subBucket?.content?.imageUrl[0].url}
                          ></source>
                        </video>
                      ) : (
                        <video poster={iOS && !subBucket?.content?.imageUrl[0]?.metaData?.thumbnail ? videoThumnail : subBucket?.content?.imageUrl[0]?.metaData?.thumbnail? subBucket?.content?.imageUrl[0]?.metaData?.thumbnail:""} style={{width: "100%", height: "100%"}}>
                          <source
                            src={subBucket?.content?.imageUrl[0].url}
                          ></source>
                        </video>
                      )}

                      {isPlaying ? (
                        // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                        ""
                      ) : subBucket?.content?.imageUrl[0]?.url ? (
                        <>
                          <div
                            className="toggle-view-icon-outer"
                            onClick={handlePlayVideo}
                          >
                            <div className="toggle-view-icon">
                              <FontAwesomeIcon
                                className="ml-[40px] text-[#989898]"
                                icon={faPlay}
                                style={{ color: "#111111b0", fontSize: "40px" }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <FontAwesomeIcon icon="fa-solid fa-play" /> */}

                    {subBucket?.cardType ? (
                      subBucket.cardType == "twitter" ? (
                        ""
                      ) : subBucket.cardType == "facebook" ? (
                        <div className="second-html">
                          {parse(subBucket.content.html)}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : subBucket?.content?.imageUrl.length > 0 &&
                  subBucket?.content?.imageUrl[0].urlType == "Video" &&
                  subBucket.content.html ? (
                  <div
                    className={`cardContent cardDetailContent video video-content-inner ${
                      isPlaying ? "active" : ""
                    }`}
                  >
                    {subBucket?.cardType ? (
                      subBucket.cardType == "twitter" ? (
                        <div className="css-146c3p1">
                          {parse(subBucket.content.html)}
                        </div>
                      ) : subBucket.cardType == "facebook" ? (
                        subBucket?.content?.imageUrl[0].isAdLibrary ? (
                          <>
                            <div className="ad-lib-first-html">
                              {parse(subBucket.content.html)}
                            </div>
                          </>
                        ) : (
                          <Paragraphs
                            text={subBucket.content.html}
                            maxLength={380}
                          />
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {parse(videoIframe)} */}

                    {subBucket?.content?.imageUrl[0]?.url ? (
                      <div className="video-container">
                        {isPlaying ? (
                          <video controls>
                            <source
                              src={subBucket?.content?.imageUrl[0].url}
                            ></source>
                          </video>
                        ) : (
                          <video poster={iOS && !subBucket?.content?.imageUrl[0]?.metaData?.thumbnail ? videoThumnail : subBucket?.content?.imageUrl[0]?.metaData?.thumbnail? subBucket?.content?.imageUrl[0]?.metaData?.thumbnail:""} style={{width: "100%", height: "100%"}}>
                            <source
                              src={subBucket?.content?.imageUrl[0].url}
                            ></source>
                          </video>
                        )}

                        {isPlaying ? (
                          // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                          ""
                        ) : subBucket?.content?.imageUrl[0]?.url ? (
                          <>
                            <div
                              className="toggle-view-icon-outer"
                              onClick={handlePlayVideo}
                            >
                              <div className="toggle-view-icon">
                                <FontAwesomeIcon
                                  className="ml-[40px] text-[#989898]"
                                  icon={faPlay}
                                  style={{
                                    color: "#111111b0",
                                    fontSize: "40px",
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : subBucket?.cardType == "youtube" ? (
                      <div className="youtube-container">
                        <iframe
                          src={
                            `https://www.youtube.com/embed/` +
                            subBucket?.content?.sourceUrl
                              .split("v=")[1]
                              .split("&")[0]
                          }
                          width="100%"
                          height="500"
                        ></iframe>
                      </div>
                    ) : subBucket?.cardType == "facebook" &&
                      subBucket?.content?.imageUrl[0]?.metaData ? (
                      <div className="youtube-container">
                         <VideoEmbed url={subBucket?.content?.imageUrl[0]?.metaData?.embedUrl} />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <FontAwesomeIcon icon="fa-solid fa-play" /> */}
                    {subBucket?.cardType ? (
                      subBucket.cardType == "twitter" ? (
                        ""
                      ) : subBucket.cardType == "facebook" ? (
                        subBucket?.content?.imageUrl[0].isAdLibrary ? (
                          <>
                            <div className="ad-lib-second-html">
                              {parse(subBucket.content.html)}
                            </div>
                          </>
                        ) : (
                          <div className="second-html">
                            {parse(subBucket.content.html)}
                          </div>
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : subBucket.content.html ? (
                  <div className="cardContent cardDetailContent">
                    {subBucket.cardType == "bookmark" ? (
                      <a href={`${sourceUrl}`} target="_blank">
                        <img
                          className="w-full"
                          src={BookmarkPreview}
                          alt="card-image"
                        />
                      </a>
                    ) : (
                      <FParagraph
                        text={subBucket.content.html}
                        maxLength={380}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className="cardContent cardDetailContent"
                    onClick={handlePopup}
                  >
                    <div className="container fb-image cardImage mt-6 btest">
                      {subBucket?.content?.imageUrl.length > 0 ? (
                        subBucket.cardType == "File" &&
                        subBucket?.content?.imageUrl[0]?.url == null ? (
                          <img
                            style={{width: "70%"}}
                            src={FileDownload}
                            alt="card-image"
                          />
                        ) : subBucket.cardType == "File" &&
                          allowedImageTypes.includes(
                            subBucket?.content?.imageUrl[0]?.urlType
                          ) ? (
                          <img
                            className="w-full"
                            src={subBucket?.content?.imageUrl[0].url}
                            alt="card-image"
                          />
                        ) : subBucket.cardType != 'File' ? (
                              <img
                                className="w-full"
                                src={subBucket?.content?.imageUrl[0].url}
                                alt="card-image"
                              />
                          ) : (
                          <img
                            style={{width: "70%"}}
                            src={FileDownload}
                            alt="card-image"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
              {/* <p>Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
                            <div className="cardImage w-full mt-6">
                                <img className="w-full" src={cardImage} alt="card-image" />
                            </div> */}
              {/* </div> */}

              {popup && (
                <div
                  className="bucketCreationPopup relative !z-[999]"
                  style={{ height: "100%" }}
                  onClick={closePopup}
                >
                  <form
                    className="addBucket relative w-[85%] md:w-[55%]"
                    onClick={handleChildClick}
                  >
                    <span
                      onClick={closePopup}
                      className="cls-btn absolute right-0 top-[7px] w-[30px] h-[30px] bg-[#FF6600] flex justify-center items-center rounded-full"
                      style={{ zIndex: 1 }}
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer text-white text-[20px]"
                        icon={faClose}
                      />
                    </span>
                    {subBucket?.content ? (
                      subBucket.content.imageUrl.length > 0 &&
                      subBucket?.content?.imageUrl[0].urlType == "Image" &&
                      subBucket.content.html ? (
                        <div
                          className="popup-img"
                          style={{ maxHeight: "100vh" }}
                        >
                          {/* {(subBucket?.cardType)? (subBucket.cardType == 'twitter' ?  
                                                        parse(subBucket.content.html)
                                                    : subBucket.cardType == 'facebook' ? 
                                                    <div className="first-html">
                                                        {parse(subBucket.content.html)}
                                                    </div>
                                                    : ""):("")} */}
                          {subBucket?.cardType ? (
                            subBucket.cardType == "twitter" ? (
                              <div className="css-146c3p1">
                                {parse(subBucket.content.html)}
                              </div>
                            ) : subBucket.cardType == "facebook" ? (
                              subBucket?.content?.imageUrl[0].isAdLibrary ? (
                                <>
                                  <div className="ad-lib-first-html">
                                    {parse(subBucket.content.html)}
                                  </div>
                                </>
                              ) : (
                                <div className="first-html">
                                  {parse(subBucket.content.html)}
                                  {/* <Paragraphs
                                                                text={subBucket.content.html}
                                                                maxLength={380}
                                                            /> */}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {/* {
                                                            (subBucket?.content?.imageUrl.length > 0 && subBucket?.content?.imageUrl[0].urlType == 'Image')? (
                                                                <img className="w-full" src={subBucket?.content?.imageUrl[0].url} alt="card-image"/>
                                                            ) :("")
                                                        } */}

                          {subBucket?.content?.imageUrl.length > 0 &&
                          subBucket?.content?.imageUrl[0].urlType == "Image" &&
                          subBucket?.cardType !== "youtube" ? (
                            subBucket?.content?.imageUrl?.map((image,index) => (
                              <img
                              className="w-full dffsfs"
                              src={image.url}
                              alt="card-image"
                              onClick={subBucket?.cardType? subBucket?.cardType == 'fullScreen'? () => handlePopupFullPage(image.url) : "" : ""}
                            />
                          ))
                          ) : subBucket?.cardType == "youtube" ? (
                            <iframe
                              src={
                                `https://www.youtube.com/embed/` +
                                subBucket?.content?.sourceUrl
                                  .split("v=")[1]
                                  .split("&")[0]
                              }
                              width="100%"
                              height="500px"
                            ></iframe>
                          ) : (
                            ""
                          )}

                          {subBucket?.cardType ? (
                            subBucket.cardType == "twitter" ? (
                              ""
                            ) : subBucket.cardType == "facebook" ? (
                              subBucket?.content?.imageUrl[0].isAdLibrary ? (
                                <>
                                  <div className="ad-lib-second-html">
                                    {parse(subBucket.content.html)}
                                  </div>
                                </>
                              ) : (
                                <div className="second-html">
                                  {parse(subBucket.content.html)}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {/* {(subBucket?.cardType)? (subBucket.cardType == 'twitter' ?  
                                                        ""
                                                    : subBucket.cardType == 'facebook' ? 
                                                        <div className="second-html">
                                                            {parse(subBucket.content.html)}
                                                        </div>
                                                    : ""):("")} */}

                          {/* {parse(subBucket.content.html)}
                                                    <img className="w-full" src={subBucket?.content?.imageUrl} alt="card-image" /> */}
                        </div>
                      ) : subBucket?.content?.imageUrl.length > 0 &&
                        subBucket?.content?.imageUrl[0].urlType == "Video" &&
                        subBucket.content.html ? (
                        <div style={{ maxHeight: "100vh", overflow: "auto" }}>
                          <div
                            className={`cardContent cardDetailContent video video-content-inner ${
                              isPlayingPopup ? "active" : ""
                            }`}
                          >
                            {/* <Paragraphs
                                                        text={subBucket?.content?.html}
                                                        maxLength={380} /> */}

                            <div className="detail-page-see-data">
                              <div className={`detail-text-wrapper active`}>
                                {subBucket?.cardType ? (
                                  subBucket.cardType == "twitter" ? (
                                    <div className="css-146c3p1">
                                      {parse(subBucket.content.html)}
                                    </div>
                                  ) : subBucket.cardType == "facebook" ? (
                                    subBucket?.content?.imageUrl[0]
                                      .isAdLibrary ? (
                                      <>
                                        <div className="ad-lib-first-html">
                                          {parse(subBucket.content.html)}
                                        </div>
                                      </>
                                    ) : (
                                      parse(subBucket.content.html)
                                    )
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* {parse(subBucket?.content?.html)} */}
                            {subBucket?.content?.imageUrl[0]?.url ? (
                              <div className="video-container">
                                {isPlayingPopup ? (
                                  <video controls>
                                    <source
                                      src={subBucket?.content?.imageUrl[0].url}
                                    ></source>
                                  </video>
                                ) : (
                                  <video poster={iOS && !subBucket?.content?.imageUrl[0]?.metaData ? videoThumnail : subBucket?.content?.imageUrl[0]?.metaData?.thumbnail? subBucket?.content?.imageUrl[0]?.metaData?.thumbnail:""} style={{width: "100%", height: "100%"}}>
                                    <source
                                      src={subBucket?.content?.imageUrl[0].url}
                                    ></source>
                                  </video>
                                )}

                                {isPlayingPopup ? (
                                  // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                                  ""
                                ) : subBucket?.content?.imageUrl[0]?.url ? (
                                  <>
                                    <div
                                      className="toggle-view-icon-outer"
                                      onClick={handlePlayVideoPopup}
                                    >
                                      <div className="toggle-view-icon">
                                        <FontAwesomeIcon
                                          className="ml-[40px] text-[#989898]"
                                          icon={faPlay}
                                          style={{
                                            color: "#111111b0",
                                            fontSize: "40px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : subBucket?.cardType == "youtube" ? (
                              <div className="youtube-container">
                                <iframe
                                  src={
                                    `https://www.youtube.com/embed/` +
                                    subBucket?.content?.sourceUrl
                                      .split("v=")[1]
                                      .split("&")[0]
                                  }
                                  width="100%"
                                  height="500"
                                ></iframe>
                              </div>
                            ) : subBucket?.cardType == "facebook" &&
                              subBucket?.content?.imageUrl[0]?.metaData ? (
                              <div className="youtube-container">
                                <VideoEmbed url={subBucket?.content?.imageUrl[0]?.metaData?.embedUrl} />
                                {/* <iframe
                                  src={
                                    subBucket?.content?.imageUrl[0]?.metaData
                                      ?.embedUrl
                                  }
                                  width="560"
                                  height="315"
                                  className="facebook-embed-iframe"
                                ></iframe> */}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* <FontAwesomeIcon icon="fa-solid fa-play" /> */}

                            {subBucket?.cardType ? (
                              subBucket.cardType == "twitter" ? (
                                ""
                              ) : subBucket.cardType == "facebook" ? (
                                subBucket?.content?.imageUrl[0].isAdLibrary ? (
                                  <>
                                    <div className="ad-lib-second-html">
                                      {parse(subBucket.content.html)}
                                    </div>
                                  </>
                                ) : (
                                  <div className="second-html">
                                    {parse(subBucket.content.html)}
                                  </div>
                                )
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : subBucket.content.html ? (
                        parse(subBucket.content.html)
                      ) : (
                        <div style={{ maxHeight: "100vh", overflow: "auto" }}>

                      {subBucket?.content?.imageUrl.length > 0 ? (
                        subBucket.cardType == "File" &&
                        subBucket?.content?.imageUrl[0]?.url == null ? (
                          <img
                          className="w-full"
                            src={FileDownload}
                            alt="card-image"
                          />
                        ) : subBucket.cardType == "File" &&
                          allowedImageTypes.includes(
                            subBucket?.content?.imageUrl[0]?.urlType
                          ) ? (
                          <img
                            className="w-full"
                            src={subBucket?.content?.imageUrl[0].url}
                            alt="card-image"
                          />
                        ) : subBucket.cardType != 'File' ? (
                              <img
                                className="w-full"
                                src={subBucket?.content?.imageUrl[0].url}
                                alt="card-image"
                                onClick={subBucket?.cardType? subBucket?.cardType == 'fullScreen'? () => handlePopupFullPage(subBucket?.content?.imageUrl[0]?.url) : "" : ""}
                              />
                          ) : (
                          <img
                          className="w-full"
                            src={FileDownload}
                            alt="card-image"
                          />
                        )
                      ) : (
                        ""
                      )}

                          {/* {subBucket?.content?.imageUrl.length > 0 ? (
                            <img
                              className="w-full cxcvcxvcx"
                              src={subBucket?.content?.imageUrl[0].url}
                              alt="card-image"
                            />
                          ) : (
                            ""
                          )} */}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              )}
            </div>
            {/* <div>hi</div> */}
            {subBucket?.cardType === "File" ? (
              <div
                className="carddetailfilebg-content flex items-center px-3 py-1 rounded-2xl"
                style={{ width: "120px", cursor: "pointer" }}
                onClick={() =>
                  handleDownloadFile(subBucket?.title.split(".").reverse()[0])
                }
              >
                <span className="rounded-[50%] p-1 flex" >
                  <img src={DownloadButton} className="" alt="copy-icon" />
                  <p className="publicytext-content ml-2">Download</p>
                </span>
              </div>
            ) : (
              <div className="flex">
                <div className="inviteBtn">
                  <div
                    className="btncard-invite rounded-full text-white sm:text-sm  md:text-md lg:text-lg"
                    href="#"
                  >
                    *Right click to download images or videos
                  </div>
                </div>
              </div>
            )}
            {/* <div className="flex">
                        <div className="inviteBtn">
                        <div className="btncard-invite rounded-full text-white sm:text-sm  md:text-md lg:text-lg" href="#">
                        
                        *Right click to download images or videoss</div>
                        </div>
                    </div> */}
            {/* Download button stylings */}
            {/* <div className="bg-[#eaeaea] flex items-center px-3 py-1 rounded-2xl" style={{width:"120px"}}>
                    <span className="rounded-[50%] p-1 flex">
                      <p className="publicytext-content">Download</p>
                      <img src={DownloadButton} className="ml-2" alt="copy-icon" />
                  </span>
                </div> */}
            {/* <div className="downloadbutton-content flex"><img src={DownloadButton} alt="downloadButton" /></div> */}

            {/* <div className="inviteBtn">
              <a
                className="downloadbtn-invite rounded-full bg-[#eaeaea] text-black sm:text-sm  md:text-md lg:text-lg flex"
                href="#"
              >
                <img
                  className="mr-2"
                  src={DownloadButton}
                  alt="DownloadButton"
                />
                Download
              </a>
            </div> */}
            {/* <br /> */}
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div className="flex">
              {/* <FontAwesomeIcon icon={faThumbsUp} /> */}
                <img src={likeIcon} alt="likeIcon" />
                <span className="social-data">{"3.5k"}</span>
              </div>
              <div className="flex">
                {/* <FontAwesomeIcon icon={faComment} />  */}
                <img src={commentIcon} alt="commentIcon" />
                <span className="social-data">{"183"}</span>
              </div>
              <div className="flex">
                {/* <FontAwesomeIcon icon={faEye} />  */}
                <img src={viewIcon} alt="viewIcon" />
                <span className="social-data">{"4.7k"}</span>
              </div>
            </div>
            <div className="flex">
              {subBucket?.content && subBucket?.cardType != "fullScreen" ? (
                subBucket.content.imageUrl.length > 1 &&
                subBucket?.content?.imageUrl[0].urlType == "Image" ? (
                  <div>
                    <SliderPopup
                      images={subBucket.content.imageUrl}
                      handleImageClick={handleImageClick}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {/* <div>
                <img
                  className="floatingmenu-imgcontent"
                  src={FloatingMenu}
                  alt="FloatingMenu"
                />
              </div> */}
            </div>

            {bookmarkhandlePopup && (
              <div
                className="bucketCreationPopup relative !z-[999]"
                style={{ height: "100%" }}
                onClick={closebookmarkPopup}
              >
                <form
                  className="addBucket relative w-[85%] md:w-[50%]"
                  onClick={handleChildClick}
                >
                  <span
                    onClick={closebookmarkPopup}
                    className="cls-btn absolute right-0 top-[7px] w-[30px] h-[30px] bg-[#FF6600] flex justify-center items-center rounded-full"
                  >
                    <FontAwesomeIcon
                      className="cursor-pointer text-white text-[20px]"
                      icon={faClose}
                    />
                  </span>
                  <img
                    className="bookmarkspopup-content"
                    src={imageUrlPopup}
                    alt="bookmarksPopup"
                  />
                </form>
              </div>
            )}
          </div>
          <div className="rightSideCardDetail">
              <h4 class="headingtext-content">Actions</h4>
              <div className="cardHeaderDetails action-border-bottom flex pt-3">
                <div className="display-content">
                  <ul className="flex flex-wrap items-center">
                    <li className="text-[14px] lg:text-[14px]">
                      <a className="flex items-center" href="/">
                        <img
                          src={CalenderIcon}
                          alt="calenderIcon"
                          style={{width:"15px"}}
                        />{" "}
                        <span className="createdtext-content pl-2">
                          Created{" "}
                          {moment(subBucket?.createdAt).format(
                            "MMM D, YYYY @ hh:mm A"
                          )}
                        </span>
                      </a>{" "}
                    </li>
                  </ul>
                </div>
                <div className="display-content">
                  <ul className="flex flex-wrap items-center">
                    <li className="text-[14px] lg:text-[14px]">
                      <a className="flex items-center" href="/">
                        <span className="createdtext-content pl-2">
                          Last updated{" "}
                          {moment(subBucket?.updatedAt).format(
                            "MMM D, YYYY @ hh:mm A"
                          )}
                        </span>
                      </a>{" "}
                    </li>
                  </ul>
                </div>
            </div>
            <div className="display-content button-section">
                <ul className="inline-flex items-center">
                  <li className="text-[14px] lg:text-[14px]">
                      <a
                        href="#"
                        className="flex items-center action-button-inner"
                        onClick={handleCopyto}
                      >
                        <img src={copyScript} alt="copy-to" />
                        <span className="actionicon-content pl-1">Copy Script</span>
                      </a>
                    </li>
                    {subBucket?.cardType === "File" ? (
                      <li className="text-[14px] lg:text-[14px] margin-button-center">
                        <a
                          href="#"
                          className="flex items-center action-button-inner"
                          onClick={() =>
                            handleDownloadFile(subBucket?.title.split(".").reverse()[0])
                          }
                        >
                          <img src={DownloadButton} className="w-6" alt="copy-icon" />
                          <span className="actionicon-content pl-1">Download</span>
                        </a>
                      </li>
                      ) : (
                        ""
                      )}
                      {subBucket?.cardType != "File" ? (
                        <li className="text-[14px] lg:text-[14px] margin-button-center">
                          <a
                            href={`${sourceUrl}`}
                            target="_blank"
                            className="flex items-center action-button-inner"
                          >
                            <img src={originalLink} alt="source-link" />
                            <span className="actionicon-content pl-1">
                              Original Link
                            </span>
                          </a>
                          </li>
                      ) : (
                        ""
                      )}
                </ul>
            </div>
            <div className="display-content button-section">
              <ul className="inline-flex items-center">
                    <li className="text-[14px] lg:text-[14px]">
                        <a
                          href="#"
                          className="flex items-center action-button-inner"
                          onClick={handleMoveto}
                        >
                          {/* <FontAwesomeIcon
                            className="transform rotate-45"
                            icon={faArrowUp}
                          /> */}
                          <img src={moveTo} alt="copy-to" />
                          <span className="actionicon-content pl-1">Move to</span>
                        </a>
                      </li>
                      <li className="text-[14px] lg:text-[14px] margin-button-center">
                        <a
                          href="#"
                          className="flex items-center action-button-inner"
                          onClick={handleCopyto}
                        >
                          <img src={copyScript} alt="copy-to" />
                          <span className="actionicon-content pl-1">Copy to</span>
                        </a>
                      </li>
                      <li className="text-[14px] lg:text-[14px]">
                        <a
                          href="#"
                          className="flex items-center action-button-inner"
                          onClick={handleDelete}
                        >
                          <img
                              className="w-4 trash-icon"
                              src={trashIcon}
                              alt="trashIcon"
                            />
                          <span className="actionicon-content pl-1">Delete</span>
                        </a>
                      </li>
                  </ul>
            </div>
            <div className="notesHolder">
              <div style={{ display: "inline-flex" }}>
                <h4 className="headingtext-content">Notes</h4>
                {UserPermission === "Viewer" ? (
                  <>
                    <span>
                      <img
                        className="subbuckets-dark "
                        src={EditIcon}
                        alt="editIcon"
                        style={{
                          width: "17px",
                          marginLeft: "12px",
                          objectFit: "contain",
                          transform: "translateY(-2px)",
                        }}
                      />
                    </span>
                  </>
                ) : (
                  <span onClick={handleEditClick}>
                    <img
                      className="subbuckets-dark "
                      src={EditIcon}
                      alt="editIcon"
                      style={{
                        width: "17px",
                        marginLeft: "12px",
                        objectFit: "contain",
                        transform: "translateY(-2px)",
                      }}
                    />
                  </span>
                )}
              </div>
              <div
                className="border border-[#e5e5e5] dark:border-[#202F4F] border-1 rounded-md overflow-hidden"
                style={{ resize: "horizontal" }}
              >
                {/* {code.length === 0 ?   
                               <ReactQuill
                               theme="snow"
                               modules={modules}
                               formats={formats}
                               value={code}
                               onChange={handleProcedureContentChange}
                               onFocus={handleEditorFocus}
                               onClick={handleEditorClick}
                               // onBlur={()=>updateCard(cardId.id)}
                               onKeyUp={handleKeyUp}
                               onPaste={handlePaste}
                              
                               />
                      :
                           
                      */}

                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={code}
                  onChange={handleProcedureContentChange}
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                  readOnly={readOnly}
                />
                {/* } */}

                {/* <textarea className=" w-full p-3 !h-60 hover:outline-none" placeholder="Add Notes..." /> */}
                {isEditorFocused && (
                  <div
                    className="backgroundcard-rightcontent bg-[#F7F7F7] px-3 py-4 flex items-center"
                    onClick={handleClick}
                  >
                    {/* <a className="savetext-button rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg" href="#" style={{filter : "drop-shadow(rgba(0, 0, 0, 0.16) 0px 10px 20px)"}} onClick={()=>updateCard(cardId.id)} >Save</a>
                                        <a href="#" className="canceltext-content ml-8" onClick={handleEditorCancel}>Cancel</a> */}
                  </div>
                )}
              </div>
            </div>
            <div
              className={`fullpageClick ${isActive ? "active" : ""}`}
              onClick={() => updateCard(cardId.id)}
            ></div>
            <div className="tags !border-0 tagspace-notecontent tags-box-relative">
              <h4 className="headingtext-content">Tags</h4>
              <div
                className={`tagsHolder tag ${
                  UserPermission === "Viewer" ? "pointer-events-none" : ""
                }`}
                onClick={handleFocus}
              >
                <TagsInput
                  className={`tagname-tagcontent ${tagsWidth}`}
                  value={tags}
                  onChange={handleTagsChange}
                  inputValue={inputValueSet}
                  onChangeInput={(e) => handleSetInputValue(e)}
                />
                {/* {subBucket?.tags?.map((tag,index) => (
                                // console.log(tag.name,"tags listing");
                                <p className="!pt-0 !pb-1" style={{backgroundColor: (index % 2 === 0) ? '#eadbff' : '#f7f8df'}}>
                                    <span className="tagname-textcontent">{tag.name}</span>
                                    <a className="pl-1" href="#">x</a>
                                </p>
                            ))} */}
              </div>

              {isTagOpen && filteredOptions.length && (
                <div className="drpdwn-position">
                  <nav>
                    <div className="tab-scrolls tagscard">
                      <ul className="dropdown">
                        {filteredOptions.map((option) => (
                          <li key={option._id}>
                            <p onClick={() => handleSelectChange(option)}>
                              {option.name}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </nav>
                </div>
              )}
            </div>
            <p className="separatetext-content">
              *Separate tags with (,) or return key
            </p>
            {subBucket?.landingPage ? (
              subBucket?.landingPage?.sourceUrl ? (
                subBucket?.landingPage?.sourceUrl != "" &&
                subBucket?.landingPage?.sourceUrl != "undefined" ? (
                  <>
                    <div className="landingpage-content" style={{display: "none"}}>Landing Page</div>
                    <div className="flex" style={{display: "none"}}>
                      <div className="sb-cap-scr">
                        {
                          subBucket?.landingPage?.status == false && subBucket?.landingPage?.sourceUrl ?
                          <div>
                            <div class="loader-sb-cap-scr">
                                {loading && <Roller color={`#FFFFFF`} />}
                                <div className="loadingtext-content">loading...</div>
                            </div>
                            
                          </div>
                          : <a
                          href={
                            subBucket?.landingPage?.status == true && subBucket?.landingPage?.url
                                ? subBucket?.landingPage?.url
                                : subBucket?.landingPage?.status == false && subBucket?.landingPage?.sourceUrl? LandingPage : LandingPageNoPreview
                          }
                          target="_blank"
                        >
                          {
                            imageSrc ?
                            <img
                              className="landing-imgcontent"
                              alt="LandingPage"
                              src={imageSrc}
                            /> : ""
                          }
                        </a>
                        }
                         
                      </div>
                    </div>
                    <div className="landing-cardcontent" style={{display: "none"}}>
                      <a
                        href={subBucket?.landingPage?.sourceUrl}
                        target="_blank"
                      >
                        <div className="purple-imgcontent">
                          <img
                            className="pr-2"
                            src={PurpleLink}
                            alt="PurpleLink"
                          />
                          {/* {subBucket?.landingPage?.sourceUrl.split("?")[0]} */}
                          Visit Website
                        </div>
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div className="rightSideCardLanding">
            <div className="bottom-sec-np">
              <div className="bottom-first-sec">
                <h4 class="headingtext-content">Ad details</h4>
              </div>
              <div className="bottom-second-sec">
                  <div className="flex">
                    <div className="tooltip">
                        <span className="tooltip-text bucket">
                            Refresh Coming Soon
                        </span>
                        <img className="refreshIcon"
                              src={refresh}
                              alt="trashIcon"
                            />
                    </div>
                    <div className="tooltip">
                        <span className="tooltip-text bucket">
                          Created{" "}
                            {moment(subBucket?.createdAt).format(
                              "MMM D, YYYY @ hh:mm A"
                            )}
                        </span>
                        <img className="questionIcon"
                              src={question}
                              alt="trashIcon"
                            />
                    </div>
                  </div>
              </div>
            </div>
            <div className="bottom-sec-np bottom-sec-c">
               <span className="bottom-first-sec">Status</span> <span className="bottom-second-sec ads-status">Active</span>
            </div>
            <div className="bottom-sec-np bottom-sec-c">
               <span className="bottom-first-sec">Launched</span> <span className="bottom-second-sec">Oct 10, 2024</span>
            </div>
            <div className="bottom-sec-np bottom-sec-c">
               <span className="bottom-first-sec">Run time</span> <span className="bottom-second-sec">3 days</span>
            </div>
            <div className="bottom-sec-np bottom-sec-c">
               <span className="bottom-first-sec">Platform</span> <span className="bottom-second-sec flex"><img src={adfbicon} className="platform-icon"/><img src={adinstaicon} className="platform-icon"/></span>
            </div>
            {subBucket?.landingPage ? (
                subBucket?.landingPage?.sourceUrl ? (
                  subBucket?.landingPage?.sourceUrl != "" &&
                  subBucket?.landingPage?.sourceUrl != "undefined" ? (
                    <>
                      <div className="landingpage-content">Landing Page</div>
                      <div className="flex">
                        <div className="sb-cap-scr">
                          {
                            subBucket?.landingPage?.status == false && subBucket?.landingPage?.sourceUrl ?
                            <div>
                              <div class="loader-sb-cap-scr">
                                  {loading && <Roller color={`#FFFFFF`} />}
                                  <div className="loadingtext-content">loading...</div>
                              </div>
                              
                            </div>
                            : <a
                            href={
                              subBucket?.landingPage?.status == true && subBucket?.landingPage?.url
                                  ? subBucket?.landingPage?.url
                                  : subBucket?.landingPage?.status == false && subBucket?.landingPage?.sourceUrl? LandingPage : LandingPageNoPreview
                            }
                            target="_blank"
                          >
                            {
                              imageSrc ?
                              <img
                                className="landing-imgcontent"
                                alt="LandingPage"
                                src={imageSrc}
                              /> : ""
                            }
                          </a>
                          }
                          
                        </div>
                      </div>
                      <div className="landing-cardcontent">
                        <a
                          href={subBucket?.landingPage?.sourceUrl}
                          target="_blank"
                        >
                          <div className="purple-imgcontent">
                            <img
                              className="pr-2"
                              src={PurpleLink}
                              alt="PurpleLink"
                            />
                            {/* {subBucket?.landingPage?.sourceUrl.split("?")[0]} */}
                            Visit Website
                          </div>
                        </a>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
          </div>
        </div>
        <div className="bottom-sec-np">
          <div className="flex bottom-np-button">
              <ul className="inline-flex items-center">
                {subBucket?.cardType === "File" ||
                  subBucket?.cardType === "bookmark" ? null : (
                    <>
                    <li className="text-[14px] lg:text-[14px]">
                      <a
                        href="#"
                        className="flex items-center action-button-inner"
                        onClick={() => FetchCard(1)}
                      >
                        <img
                          className="mr-[7px] next-icons"
                          src={PreviousIcon}
                          alt="PreviousIcon"
                        />
                        <span className="actionicon-content pl-1">Previous</span>
                      </a>
                    </li>
                      
                    <li className="text-[14px] lg:text-[14px] margin-button-center">
                      <a
                        href="#"
                        className="flex items-center action-button-inner"
                        onClick={() => FetchCard(0)}
                      >
                        
                        <span className="actionicon-content pl-1">Next</span>
                        <img
                          className="ml-[7px] next-icons"
                          src={NextIcon}
                          alt="NextIcon"
                        />
                      </a>
                    </li>
                    </>
                  )}
              </ul>
            </div>
        </div>
        <Transition appear show={openCopy} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeCopyModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Copy To
                    </Dialog.Title>
                    <div
                      style={{ display: "block" }}
                      className="custom-list-dropdown"
                    >
                      <div className="flex fliesbucket-inputcontent">
                        <input
                          type="text"
                          placeholder="Select a bucket"
                          value={searchBucket}
                          onChange={handleBucketInputChange}
                        />
                        {/* <span className="dwondownIcon">
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", color: "#707070" }}
                            icon={faChevronDown}
                            onClick={handleBucketOpen}
                          />
                        </span> */}
                        <span className="dwondownIcon ">
                          {searchBucket ? (
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                                color: "#707070",
                                paddingTop: "8px",
                              }}
                              icon={faClose}
                              onClick={handleBucketClear}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                                color: "#707070",
                                paddingTop: "8px",
                              }}
                              icon={faSearch}
                              onClick={handleBucketOpen}
                            />
                          )}
                        </span>
                        <div className="tab-scrolls singlecard">
                          <ul className="dropdown">
                            {filteredBuckets?.map((option) => (
                              <li key={option._id}>
                                <p onClick={() => handleBucketChange(option)}>
                                  {option.name}
                                </p>
                                {option.subBuckets &&
                                option.subBuckets.length > 0 ? (
                                  <ul className="sub-dropdown">
                                    {option.subBuckets.map((subTag) => (
                                      <li key={subTag._id}>
                                        <p
                                          onClick={() =>
                                            handleSubBucketSelectChange(
                                              subTag,
                                              "copy"
                                            )
                                          }
                                        >
                                          -- {subTag.name}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {/* <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotopcard-content" onClick={()=>handleUpdateCardMenu(cardId.id)}>
                  <span className="savebutton-context">Copy</span>
                </button> */}
                      <div className="flex justify-end">
                        <hr />
                        <div
                          className="movetotopcard-content cancelsinglecard-content"
                          style={{ cursor: "pointer" }}
                          onClick={closeCopyModal}
                        >
                          Cancel
                        </div>
                        <button
                          style={{
                            filter:
                              "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                          }}
                          className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotopcard-content"
                          onClick={() => handleUpdateCardMenu(cardId.id)}
                        >
                          <span className="savebutton-context">Copy</span>
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={isOpenss} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete?
                      </p>
                    </div>

                    <div className="mt-4">
                      <button
                        style={{
                          background: "#FF6600",
                          width: "80px",
                          color: "#fff",
                          padding: "10px",
                          borderRadius: "50px",
                        }}
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => handleDeleteCard(cardId.id)}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          background: "#000",
                          width: "80px",
                          color: "#fff",
                          padding: "10px",
                          borderRadius: "50px",
                        }}
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeCardModal}
                      >
                        No
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={openMove} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeMoveModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Move To
                    </Dialog.Title>
                    <div
                      style={{ display: "block" }}
                      className="custom-list-dropdown"
                    >
                      <div className="flex fliesbucket-inputcontent">
                        <input
                          type="text"
                          placeholder="Select a bucket"
                          value={searchBucket}
                          onChange={handleBucketInputChange}
                        />
                        {/* <span className="dwondownIcon">
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", color: "#707070" }}
                            icon={faChevronDown}
                            onClick={handleBucketOpen}
                          />
                        </span> */}
                        <span className="dwondownIcon ">
                          {searchBucket ? (
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                                color: "#707070",
                                paddingTop: "8px",
                              }}
                              icon={faClose}
                              onClick={handleBucketClear}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                                color: "#707070",
                                paddingTop: "8px",
                              }}
                              icon={faSearch}
                              onClick={handleBucketOpen}
                            />
                          )}
                        </span>
                        <div className="tab-scrolls singlecard">
                          <ul className="dropdown">
                            {filteredBuckets?.map((option) => (
                              <li key={option._id}>
                                <p onClick={() => handleBucketChange(option)}>
                                  {option.name}
                                </p>
                                {option.subBuckets &&
                                option.subBuckets.length > 0 ? (
                                  <ul className="sub-dropdown">
                                    {option.subBuckets.map((subTag) => (
                                      <li key={subTag._id}>
                                        <p
                                          onClick={() =>
                                            handleSubBucketSelectChange(
                                              subTag,
                                              "move"
                                            )
                                          }
                                        >
                                          -- {subTag.name}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <hr />
                        <div
                          className="movetotopcard-content cancelsinglecard-content"
                          style={{ cursor: "pointer" }}
                          onClick={closeMoveModal}
                        >
                          Cancel
                        </div>
                        <button
                          style={{
                            filter:
                              "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                          }}
                          className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotopcard-content"
                          onClick={() => handleUpdateCardMenu(cardId.id)}
                        >
                          <span className="savebutton-context">Move</span>
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={openCopyLink} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyLinkModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy and Paste this URL
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="URL"
                              value={publicUrl}
                              className="publicUrlInput"
                              // onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeCopyLinkModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleCopyText(publicUrl)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
        <ToastContainer />
      </div>
      )}
    </div>
  );
}

export default CardDetail;
